import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Select,
  Stack,
  Template,
  TextInput,
} from '@pluto-tv/assemble';
import {IFeaturedGroupsSearchValues} from 'models/featuredGroup';
import {useUserRegions} from 'helpers/useUserRegions';
import {useFeaturedGroupsFilters} from 'components/featuredGroupList/providers/FeaturedGroupsFiltersProvider';
import {useFeaturedGroupsSearchQuery} from 'components/featuredGroupList/providers/FeaturedGroupsSearchQueryProvider';
import MonthYearPicker from 'components/featuredGroupList/components/MonthYearPicker';
import {getCurrentMonth, getCurrentYear} from 'components/featuredGroupList/utils/dateUtils';
import Autocomplete from './Autocomplete';

interface IFeaturedGroupsSearchProps {
  onCollapse: () => void;
  onSearch: (search: Partial<IFeaturedGroupsSearchValues>) => void;
  onClear: () => void;
  isSearchActive: boolean;
  setIsSearchActive: (value: boolean) => void;
  isExpanded?: boolean;
  activeRegionSelected: string;
}

const groupNameId = 'groupName';

const initialState: IFeaturedGroupsSearchValues = {
  monthYear: {month: getCurrentMonth(), year: getCurrentYear()},
};
const FeaturedGroupsSearch = ({
  onCollapse,
  onSearch,
  onClear,
  isExpanded,
  isSearchActive,
  setIsSearchActive,
  activeRegionSelected,
}: IFeaturedGroupsSearchProps): React.ReactElement => {
  const {activeRegions} = useUserRegions();
  const {isFetching, isError} = useFeaturedGroupsSearchQuery();
  const {
    model: searchModel,
    setModel: searchSetModel,
    setFields: searchSetFields,
    form: searchForm,
    onBlur: searchOnBlur,
    reset: searchReset,
    getValidation: searchGetValidation,
    onChange: searchOnChange,
  } = useFeaturedGroupsFilters();

  const activeRegionsOptions = React.useMemo(
    () =>
      activeRegions.map(ar => ({
        label: `${ar.name} (${ar.code})`,
        value: ar.code,
      })),
    [activeRegions],
  );

  const handleSearch = async () => {
    // For some reason the unit tests can't see this function
    if (!searchGetValidation) {
      onSearch({...searchModel});
      return;
    }

    const validation = await searchGetValidation();

    if (!validation.state.isValid) {
      return;
    }

    onSearch({...validation.model});
  };

  const handleClear = () => {
    searchReset();
    searchSetFields({...initialState});
    onClear();
  };

  React.useLayoutEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        const featuredGroupTitleInput = document.getElementById(groupNameId);
        featuredGroupTitleInput?.focus({
          preventScroll: true,
        });
      }, 500);
    }
  }, [isExpanded]);

  React.useEffect(() => {
    if (!isError && !isFetching) {
      if (!activeRegionSelected) {
        searchSetModel({...initialState, ...searchModel});
        return;
      }

      searchSetModel({...initialState, ...searchModel, activeRegion: activeRegionSelected.toUpperCase()});
      setIsSearchActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRegionSelected, isError, isFetching]);

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon id='closeFilters' icon='collapseleft' size='large' onClick={onCollapse} />
          </Cluster>
          <Divider color='graphite' />
        </Stack>
      </Template>
      <Template label='cover'>
        <form
          id='featured-group-search-form'
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <FormItem {...searchForm.monthYear} onBlur={() => searchOnBlur('monthYear')}>
              <MonthYearPicker
                value={{
                  month: searchModel.monthYear?.month || '',
                  year: searchModel.monthYear?.year || '',
                }}
                onChange={value => {
                  searchSetFields({monthYear: {month: value.month, year: value.year}});
                  setIsSearchActive(true);
                }}
              />
            </FormItem>
            <FormItem
              {...searchForm.groupName}
              onBlur={() => {
                searchOnBlur('groupName');
              }}
            >
              <TextInput
                id={groupNameId}
                clearable={true}
                placeholder='Title'
                value={searchModel.groupName}
                onChange={val => {
                  searchOnChange('groupName', val);
                  setIsSearchActive(true);
                }}
              />
            </FormItem>
            <FormItem {...searchForm.activeRegion} onBlur={() => searchOnBlur('activeRegion')}>
              <Select
                id='activeRegions'
                placeholder='Select'
                clearable={true}
                value={activeRegionsOptions.find(ar => ar.value === searchModel.activeRegion)}
                options={activeRegionsOptions}
                onChange={val => {
                  searchSetFields({activeRegion: val?.value});
                  setIsSearchActive(true);
                }}
                predicate='value'
              />
            </FormItem>
            <FormItem {...searchForm.channels} onBlur={() => searchOnBlur('channels')}>
              <Autocomplete
                value={searchModel.channels}
                onChange={value => {
                  searchSetFields({channels: value});
                  setIsSearchActive(true);
                }}
                activeRegions={searchModel.activeRegion ? [searchModel.activeRegion] : activeRegions.map(ar => ar.code)}
              />
            </FormItem>
            <FormItem {...searchForm.published} onBlur={() => searchOnBlur('published')}>
              <Select
                id='published'
                value={{label: searchModel.published || ''}}
                clearable={true}
                appendToBody={true}
                predicate='label'
                placeholder='Published?'
                options={[{label: 'Yes'}, {label: 'No'}]}
                onChange={val => {
                  searchSetFields({published: val?.label});
                  setIsSearchActive(true);
                }}
              />
            </FormItem>
          </Stack>
        </form>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button
              id='clearButton'
              ghost={true}
              onClick={handleClear}
              state={isFetching || !isSearchActive ? 'disabled' : ''}
            >
              Clear
            </Button>
            <Button
              id='searchButton'
              onClick={handleSearch}
              type='primary'
              state={isFetching ? 'thinking' : !isSearchActive ? 'disabled' : ''}
            >
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default FeaturedGroupsSearch;
