import * as React from 'react';

export interface ISearchData<T> {
  data: T[];
  totalCount: number;
}
export interface IHubSearchData<ISearchData, U> {
  data: ISearchData;
  totalCount: number;
  currentData: ISearchData;
  isFetching: boolean;
  isError: boolean;
  isLoading: boolean;
  search: (params: U) => void;
}

const useHubData = <T, U extends Record<string, unknown>>(
  useLazyFindQueryHook: () => [
    (params: U) => void,
    {
      data: T;
      totalCount: number;
      currentData: T;
      isLoading: boolean;
      isFetching: boolean;
      isError: boolean;
      error: Error;
      isSuccess: boolean;
    },
  ],
): IHubSearchData<T, U> => {
  const [trigger, {data, currentData, totalCount, isLoading, isFetching, isError}] = useLazyFindQueryHook();

  const doSearch = React.useCallback(
    (params: U) => {
      trigger({...params, time: new Date().getTime()});
    },
    [trigger],
  );

  return {data, currentData, totalCount, isLoading, isFetching, isError, search: doSearch};
};

export default useHubData;
