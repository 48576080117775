import {getHubSearchLazyLoadProviderContext} from 'components/providers/hub/getHubSearchLazyLoadProvider';
import {IHubSearchData, ISearchData} from 'components/hooks/hub/useSearchHubData';
import {ICarouselConfig, ICarouselConfigSearch} from 'models/carouselConfigs';

const {useHubSearchLazyLoadProvider: useCarouselLazyLoadProvider, HubSearchLazyLoadProvider: CarouselLazyLoadProvider} =
  getHubSearchLazyLoadProviderContext<
    ICarouselConfig,
    ICarouselConfigSearch,
    IHubSearchData<ISearchData<ICarouselConfig>, ICarouselConfigSearch>
  >();
export {useCarouselLazyLoadProvider, CarouselLazyLoadProvider};
