import * as React from 'react';
import {Cluster, Heading, Grid, Cover, Template, Box, Button, Paragraph} from '@pluto-tv/assemble';
import {useHistory} from 'react-router-dom';

import programmingRoutes from 'routes/programming.routes';
import contentRoutes from 'routes/content.routes';
import campaignRoutes from 'routes/campaign.routes';
import runLimitsRoutes from 'routes/runLimits.routes';
import adminRoutes from 'routes/admin.routes';
import {useAppPermissions} from 'app/permissions';

export default (): JSX.Element => {
  const history = useHistory();
  const {ableTo} = useAppPermissions();

  const {
    channelEnabled,
    vodCollectionEnabled,
    featuredGroupEnabled,
    seriesEnabled,
    episodeEnabled,
    clipEnabled,
    campaignsEnabled,
    runLimitTrackerEnabled,
    licensedTitlesEnabled,
    rolesEnabled,
    usersEnabled,
    activeRegionsEnabled,
    appNamesEnabled,
    hubManagerEnabled,
  } = {
    channelEnabled: ableTo('CHANNEL_VIEW'),
    vodCollectionEnabled: ableTo('VOD_VIEW'),
    featuredGroupEnabled: ableTo('FEATURED_GROUPS_VIEW'),
    episodeEnabled: ableTo('EPISODE_VIEW'),
    clipEnabled: ableTo('CLIP_VIEW'),
    seriesEnabled: ableTo('SERIES_VIEW'),
    campaignsEnabled: ableTo('CAMPAIGN_VIEW'),
    runLimitTrackerEnabled: ableTo('RUN_LIMIT_TRACKER_VIEW'),
    licensedTitlesEnabled: ableTo('LICENSED_TITLES_VIEW'),
    rolesEnabled: ableTo('ROLE_VIEW'),
    usersEnabled: ableTo('USER_VIEW'),
    activeRegionsEnabled: ableTo('ACTIVE_REGION_VIEW'),
    appNamesEnabled: ableTo('APPNAME_VIEW'),
    hubManagerEnabled: ableTo('HUB_VIEW'),
  };

  return (
    <Cover
      scrolling={true}
      gutterTop='medium'
      gutterBottom='large'
      coverTemplateHeight='100%'
      paddingX={{mobile: 'medium', wide: 'large'}}
      paddingTop={{mobile: 'medium', wide: 'large'}}
    >
      <Template label='header'>
        <Cluster justify='space-between' align='center' space='medium'>
          <Heading truncate={true} truncateBackgroundHover='shadow' level='h1'>
            Directory
          </Heading>
        </Cluster>
      </Template>
      <Template label='cover'>
        <Box background='pewter' borderTop={true} borderSize='0.125rem' borderColor='cavern' padding='medium'>
          <Grid rowGap='medium' columnGap='large' minimum='23.125rem'>
            {channelEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Channels</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage Channels.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(programmingRoutes.paths.channelListPage)}>
                      Go To Channels
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {vodCollectionEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>VOD Collections</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage VOD Collections.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(programmingRoutes.paths.vodCollectionListPage)}>
                      Go To VOD Collections
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {featuredGroupEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Featured Groups</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage Featured Groups.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(programmingRoutes.paths.featuredGroupListPage)}>
                      Go To Featured Groups
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {hubManagerEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Hub Manager</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage Hub and Carousel configurations.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(programmingRoutes.paths.hubManagerPage)}>
                      Go To Hub Manager
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {seriesEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Series</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>Create and manage Series.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(contentRoutes.paths.seriesListPage)}>
                      Go To Series
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {episodeEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Episodes</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage Episodes.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(contentRoutes.paths.episodeListPage)}>
                      Go To Episodes
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {clipEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Clips</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>Create and manage Clips.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(contentRoutes.paths.clipListPage)}>
                      Go To Clips
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {campaignsEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Campaigns</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>Create and manage Campaigns and Primetime Carousels.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(campaignRoutes.paths.campaignListPage)}>
                      Go To Campaigns
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {runLimitTrackerEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Run Limit Tracker</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View monthly run count data for licensed titles airing on Pluto TV channels.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(runLimitsRoutes.paths.runLimitTrackerPage)}>
                      Go To Run Limit Tracker
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {licensedTitlesEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Licensed Titles</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage licensed titles and run limits.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(runLimitsRoutes.paths.licensedTitlesListPage)}>
                      Go To Licensed Titles
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {rolesEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Roles &amp; Permissions</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>
                      Define new roles and permissions to govern user level access to specific areas and features in the
                      CMS.
                    </Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(adminRoutes.paths.roleListPage)}>
                      Go To Roles
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {usersEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>User Accounts</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>View and manage CMS user accounts.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(adminRoutes.paths.userListPage)}>
                      Go To Users
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {activeRegionsEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>Active Regions</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>Configure new active regions and territory associations.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(adminRoutes.paths.activeRegionsListPage)}>
                      Go To Active Regions
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
            {appNamesEnabled && (
              <Box
                padding='small'
                borderRadius='0.5rem'
                borderSize='0.1rem'
                borderStyle='solid'
                borderBottom={true}
                borderTop={true}
                borderRight={true}
                borderLeft={true}
                background='onyx'
                backgroundHover='cavern'
              >
                <Cover gutter='small'>
                  <Template label='header'>
                    <Heading level='h3'>App Names</Heading>
                  </Template>
                  <Template label='cover'>
                    <Paragraph>Create and manage App Names and Device Type associations.</Paragraph>
                  </Template>
                  <Template label='footer'>
                    <Button type='primary' onClick={() => history.push(adminRoutes.paths.appNameListPage)}>
                      Go To App Names
                    </Button>
                  </Template>
                </Cover>
              </Box>
            )}
          </Grid>
        </Box>
      </Template>
    </Cover>
  );
};
