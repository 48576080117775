import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IAssetUploadUrlRequest, IAssetUploadUrlResponse} from 'models/assets';

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  tagTypes: ['Assets'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    createAssetUploadUrl: builder.mutation<IAssetUploadUrlResponse, IAssetUploadUrlRequest>({
      query: ({contentType, contentId, assetType, mimeType, size}) => ({
        url: `assets/${contentType}/${contentId}/${assetType}`,
        body: {mimeType, size},
        method: 'POST',
      }),
    }),
  }),
});

export const {useCreateAssetUploadUrlMutation} = assetsApi;
