import * as React from 'react';
import {ContentBoxes, ContentBox, FormItem, Grid, Select, TextInput, ISelectOption} from '@pluto-tv/assemble';

import {INestedCarouselConfigProps} from '../nestedPropsInterface';
import SourceServiceTable from 'views/programming/hubManager/carousels/edit/details/components/SourceServiceTable';
import AssociatedHubsTable from 'views/programming/hubManager/carousels/edit/details/components/AssociatedHubsTable';
import {SourceServiceModel} from 'views/programming/hubManager/carousels/edit';
import {useAppPermissions} from 'app/permissions';

interface ICarouselConfigProps extends INestedCarouselConfigProps {
  dirtyFields: any;
  sourceServiceModel: SourceServiceModel;
  setSourceServiceModel: (model: SourceServiceModel) => void;
}

export default ({
  form,
  model,
  onBlur,
  onChange,
  setFields,
  sourceServiceModel,
  setSourceServiceModel,
}: ICarouselConfigProps): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const canEdit = ableTo('CAROUSEL_EDIT');

  return (
    <ContentBoxes layout='grid' gridMaxCols={2}>
      <ContentBox title='Configurations'>
        <Grid gap='small' maxCols={2}>
          <FormItem {...form.name} onBlur={() => onBlur('name')} permission={canEdit ? '' : 'disabled'}>
            <TextInput
              onChange={value => {
                onChange('name', value);
              }}
              value={model.name}
              id='carouselNameField'
            />
          </FormItem>
          <FormItem {...form.displayModel} onBlur={() => onBlur('displayModel')} permission={canEdit ? '' : 'disabled'}>
            <Select
              predicate='value'
              clearable={true}
              value={{value: model.displayModel, label: ''}}
              id='displayModel'
              options={[
                {label: 'Basic', value: 'basic'},
                {label: 'Categories', value: 'categories'},
                {label: 'Featured', value: 'featured'},
                {label: 'Poster', value: 'poster'},
                {label: 'Resume Point', value: 'resumePoint'},
              ]}
              onChange={value => {
                const displayModel = (value as ISelectOption)?.value;
                setFields({
                  displayModel,
                });
              }}
            />
          </FormItem>
          <FormItem
            {...form.maxDisplayedTilesCount}
            onBlur={() => onBlur('maxDisplayedTilesCount')}
            permission={canEdit ? '' : 'disabled'}
          >
            <TextInput
              type='number'
              onChange={value => {
                onChange('maxDisplayedTilesCount', value);
              }}
              value={model.maxDisplayedTilesCount}
              id='maxTilesDisplayed'
            />
          </FormItem>
          <FormItem
            {...form.refreshOnUserAction}
            onBlur={() => onBlur('refreshOnUserAction')}
            permission={canEdit ? '' : 'disabled'}
          >
            <Select
              predicate='value'
              multiselect={true}
              clearable={true}
              id='refreshOnUserAction'
              value={model.refreshOnUserAction?.map(val => ({
                label: '',
                value: val,
              }))}
              options={[
                {label: 'Favorites Changed', value: 'favoritesChanged'},
                {label: 'Watchlist Changed', value: 'watchlistChanged'},
                {label: 'Consumption Changed', value: 'consumptionChanged'},
              ]}
              onChange={value =>
                setFields({
                  refreshOnUserAction: (value as ISelectOption[])?.map(ar => ar.value as any),
                })
              }
            />
          </FormItem>
          <FormItem
            {...form.onClickAction}
            onBlur={() => onBlur('onClickAction')}
            permission={canEdit ? '' : 'disabled'}
          >
            <Select
              predicate='value'
              clearable={true}
              value={{value: model.onClickAction, label: ''}}
              id='onClickAction'
              options={[
                {label: 'Play Video', value: 'playVideo'},
                {label: 'Content Details', value: 'contentDetails'},
                {label: 'Load Hub', value: 'loadHub'},
              ]}
              onChange={value => {
                const onClickAction = (value as ISelectOption)?.value;
                setFields({
                  onClickAction,
                });
              }}
            />
          </FormItem>
          <FormItem
            {...form.onClickActionOverrides}
            onBlur={() => onBlur('onClickActionOverrides')}
            permission={canEdit ? '' : 'disabled'}
          >
            <Select
              predicate='value'
              clearable={true}
              value={Object.entries(model.onClickActionOverrides || {})?.map(([key, value]) => {
                return {
                  label: '',
                  value: `${key}#${value}`,
                };
              })}
              multiselect={true}
              id='onClickActionOverrides'
              options={[
                {label: 'Channel - Play Video', value: 'channel#playVideo'},
                {label: 'Channel - Content Details', value: 'channel#contentDetails'},
                {label: 'Series - Content Details', value: 'series#contentDetails'},
                {label: 'Series - Play Video', value: 'series#playVideo'},
                {label: 'Movie - Content Details', value: 'movie#contentDetails'},
                {label: 'Movie - Play Video', value: 'movie#playVideo'},
              ]}
              onChange={value =>
                setFields({
                  onClickActionOverrides: (value as ISelectOption[])?.reduce((acc, ar) => {
                    const [key, value] = ar.value.split('#');
                    if (acc[key]) return acc;
                    return {
                      ...acc,
                      [key]: value,
                    };
                  }, {}),
                })
              }
            />
          </FormItem>
        </Grid>
      </ContentBox>
      <ContentBox title='Associated Hubs'>{model.id && <AssociatedHubsTable carouselId={model.id} />}</ContentBox>
      <ContentBox title='Sources and Services' gridItemFull={true}>
        <SourceServiceTable
          sourceServiceModel={sourceServiceModel}
          setSourceServiceModel={setSourceServiceModel}
          activeRegion={model.activeRegion?.[0] ?? ''}
        />
      </ContentBox>
    </ContentBoxes>
  );
};
