import {createApi} from '@reduxjs/toolkit/query/react';
import {getStaggeredQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {ICarouselBuilderResult} from 'views/programming/hubManager/hooks/interfaces';
import {CarouselBuilderHeaders, Urls} from 'views/programming/hubManager/hooks/constants';

function getParamsFromModel<T extends Partial<Record<keyof T, unknown>>>(searchModel: T) {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return [...acc, {[key]: encodeURIComponent(value)}];
    }
    return acc;
  }, [] as {[key: string]: string | number}[]);
}

export const carouselContentApi = createApi({
  reducerPath: 'carouselContent',
  tagTypes: ['CarouselContent'],
  baseQuery: getStaggeredQueryWithAuth(1),
  endpoints: builder => ({
    findContent: builder.query<{count: number; caouseles: ICarouselBuilderResult[]}, {ids: string[]; token: string}>({
      query: params => {
        const {ids, token} = params;
        const paramsString = getParamsFromModel<{id: string[]}>({id: ids})
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `${Urls.Builder}?${searchParams.toString()}`,
          method: 'GET',
          headers: {
            [CarouselBuilderHeaders.Authentication]: token,
          },
        };
      },
      providesTags: ['CarouselContent'],
      serializeQueryArgs: ({queryArgs}) => {
        // Exclude the token from the cache key
        const {ids} = queryArgs;
        return ids?.join('-');
      },
      keepUnusedDataFor: 3600, // Keep the cache for 1 hour
    }),
  }),
});

export const {useFindContentQuery, useLazyFindContentQuery} = carouselContentApi;
