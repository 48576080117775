import * as React from 'react';
import {
  Box,
  Button,
  Click,
  Cluster,
  Heading,
  Icon,
  Paragraph,
  Popover,
  Stack,
  Status,
  Template,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import DeleteConfirmation from 'components/deleteConfirmation';
import ActionConfirmation from 'components/actionConfirmation';
import {IValidatorMessage} from 'components/featuredGroupList/utils/validators';
import featuredGroupRoutes from 'routes/programming.routes';

export const deleteUpcomingId = 'deleteUpcoming';
export const publishUpcomingId = 'publishUpcoming';
export const titleId = (published: boolean): string => {
  return published ? 'publishedTitle' : 'upcomingTitle';
};
export const boxId = (published: boolean): string => {
  return published ? 'onAir' : 'upcoming';
};
export const editButtonId = (published: boolean): string => {
  return published ? 'editPublished' : 'editUpcoming';
};
export const duplicateButtonId = (published: boolean): string => {
  return published ? 'duplicatePublished' : 'duplicateUpcoming';
};

export interface IFeaturedGroupBoxProps {
  id: string;
  title: string;
  published: boolean;
  channelCount: number;
  channelWarnings?: {
    state: string;
    messageList: IValidatorMessage[];
  };
  startDate: string;
  onEdit: () => void;
  onPublish?: () => void;
  onDuplicate: () => Promise<void>;
  onDelete?: () => Promise<void>;
}

const FeaturedGroupBox = React.memo(
  ({
    id,
    title,
    published = false,
    channelCount,
    channelWarnings,
    startDate,
    onEdit,
    onPublish,
    onDuplicate,
    onDelete,
  }: IFeaturedGroupBoxProps) => {
    const {ableTo} = useAppPermissions();

    const canEdit = ableTo('FEATURED_GROUPS_EDIT');
    const canDelete = ableTo('FEATURED_GROUPS_DELETE');
    const canCreate = ableTo('FEATURED_GROUPS_CREATE');

    const [isDeleteSingleOpen, setIsDeleteSingleOpen] = React.useState(false);
    const [duplicateVisible, setDuplicateVisible] = React.useState(false);
    const [isActionBeingProcessed, setIsActionBeingProcessed] = React.useState(false);

    return (
      <Box
        id={boxId(published)}
        padding='xlarge'
        borderColor={published ? 'success' : 'info'}
        borderTop={true}
        borderRight={true}
        borderBottom={true}
        borderLeft={true}
        borderRadius='1rem'
        background={published ? 'successDark' : 'infoDark'}
      >
        <Stack space='large'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h2'>{published ? 'On Air' : 'Upcoming'}</Heading>
            <Status label={published ? 'Published' : 'Unpublished'} state={published ? 'success' : 'neutral'} />
          </Cluster>
          <Stack space='small'>
            <Click
              id={titleId(published)}
              color='primary'
              hoverColor='primaryLight'
              size='large'
              display='block'
              href={featuredGroupRoutes.paths.featuredGroupEditDetailsPage.replace(':id', id)}
            >
              {title}
            </Click>
            <Stack space='xsmall'>
              <Cluster space='small' align='center'>
                <Paragraph>
                  {channelCount} Channel{channelCount > 1 || channelCount === 0 ? 's' : ''}
                </Paragraph>
                {channelWarnings?.state && channelWarnings.messageList.length > 0 && (
                  <Popover trigger='mouseenter' id='featured-group-warning'>
                    <Template label='trigger'>
                      <Icon icon='warning' color='warning' />
                    </Template>
                    <Template label='popover'>
                      <Box
                        paddingX='small'
                        paddingY='small'
                        background='charcoal'
                        minWidth='12.5rem'
                        borderRadius='0px'
                      >
                        <Stack space='small'>
                          {channelWarnings.messageList.map((warning, index) => (
                            <Paragraph size='medium' key={index}>
                              <Icon icon='warning' iconAlign='baseline' color='warning' space='small'>
                                {warning.message}
                              </Icon>
                            </Paragraph>
                          ))}
                        </Stack>
                      </Box>
                    </Template>
                  </Popover>
                )}
              </Cluster>
              <Paragraph>{startDate}</Paragraph>
            </Stack>
          </Stack>
          <Cluster justify='flex-end'>
            <div></div>
            <Cluster space='small'>
              {canEdit && !published && (
                <Button
                  id={publishUpcomingId}
                  type='primary'
                  onClick={onPublish}
                  state={isActionBeingProcessed ? 'disabled' : 'normal'}
                >
                  Publish
                </Button>
              )}
              <Button
                id={editButtonId(published)}
                permission={!canEdit ? 'hidden' : ''}
                icon='edit'
                onClick={onEdit}
                state={isActionBeingProcessed ? 'disabled' : 'normal'}
              >
                Edit
              </Button>

              <Popover
                permission={!canCreate ? 'hidden' : ''}
                appendToBody={true}
                manualTrigger={true}
                visible={duplicateVisible}
                onClickOutside={() => setDuplicateVisible(false)}
              >
                <Template label='trigger'>
                  <Button
                    id={duplicateButtonId(published)}
                    icon='copy'
                    onClick={() => setDuplicateVisible(true)}
                    state={isActionBeingProcessed ? 'disabled' : 'normal'}
                  >
                    Duplicate
                  </Button>
                </Template>
                <Template label='popover'>
                  <ActionConfirmation
                    message={`Are you sure you want to duplicate ${title}?`}
                    cancelButtonFunction={() => setDuplicateVisible(false)}
                    proceedButtonFunction={async () => {
                      setDuplicateVisible(false);
                      setIsActionBeingProcessed(true);
                      await onDuplicate();
                      setIsActionBeingProcessed(false);
                    }}
                  />
                </Template>
              </Popover>

              {!published && (
                <Popover
                  permission={!canDelete ? 'hidden' : ''}
                  manualTrigger={true}
                  visible={isDeleteSingleOpen}
                  onClickOutside={() => setIsDeleteSingleOpen(false)}
                >
                  <Template label='trigger'>
                    <Button
                      id={deleteUpcomingId}
                      type='delete'
                      onClick={() => setIsDeleteSingleOpen(isDeleteSingleOpen => !isDeleteSingleOpen)}
                      state={isActionBeingProcessed ? 'disabled' : 'normal'}
                    >
                      Delete Group
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message='Are you sure you want to delete this group?'
                      cancelButtonFunction={() => setIsDeleteSingleOpen(false)}
                      proceedButtonFunction={async () => {
                        setIsDeleteSingleOpen(false);
                        if (onDelete) {
                          setIsActionBeingProcessed(true);
                          await onDelete();
                          setIsActionBeingProcessed(false);
                        }
                      }}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Cluster>
        </Stack>
      </Box>
    );
  },
);

FeaturedGroupBox.displayName = 'FeaturedGroupBox';
export default FeaturedGroupBox;
