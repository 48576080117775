import * as React from 'react';
import {TFormFields, useValidateForm} from '@pluto-tv/assemble';

import {IFeaturedGroupsSearchValues} from 'models/featuredGroup';
import {featuredGroupSearchValidator} from 'views/programming/featuredGroups/validators';

interface IFeaturedChannelsFiltersContext {
  model: Partial<IFeaturedGroupsSearchValues>;
  onChange: <K extends keyof IFeaturedGroupsSearchValues>(fieldName: K, value: IFeaturedGroupsSearchValues[K]) => void;
  setModel: (model: Partial<IFeaturedGroupsSearchValues>) => void;
  setFields: (fields: Partial<IFeaturedGroupsSearchValues>) => void;
  form: TFormFields<IFeaturedGroupsSearchValues>;
  onBlur: (fieldName: keyof IFeaturedGroupsSearchValues, setVal?: boolean) => void;
  getValidation: () => Promise<{model: Partial<IFeaturedGroupsSearchValues>; state: {isValid: boolean}}>;
  reset: () => void;
}

const FeaturedChannelsFiltersContext = React.createContext<IFeaturedChannelsFiltersContext | null>(null);

export const FeaturedGroupsFiltersProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const {model, onChange, setModel, setFields, form, onBlur, getValidation, reset} =
    useValidateForm<IFeaturedGroupsSearchValues>(featuredGroupSearchValidator, 'ask');

  const value = React.useMemo(
    () => ({
      model,
      onChange,
      setModel,
      setFields,
      form,
      getValidation,
      onBlur,
      reset,
    }),
    [form, model, onBlur, onChange, reset, setFields, setModel, getValidation],
  );

  return <FeaturedChannelsFiltersContext.Provider value={value}>{children}</FeaturedChannelsFiltersContext.Provider>;
};

export const useFeaturedGroupsFilters = (): IFeaturedChannelsFiltersContext => {
  const context = React.useContext(FeaturedChannelsFiltersContext);
  if (!context) {
    throw new Error('useFeaturedGroupsFilters must be used within a FeaturedGroupsFiltersProvider');
  }
  return context;
};
