import * as React from 'react';
import {Box, Checkbox, FormItem, Paragraph, Stack, TextInput, TSize} from '@pluto-tv/assemble';
import {DateTime as luxon} from 'luxon';
import {isNumber} from 'lodash-es';

import {IRunLimitObj} from 'models/licensedTitles';
import {useAppPermissions} from 'app/permissions';

export interface IRunLimitProps {
  height?: TSize;
  state?: 'error' | 'info' | 'warning' | 'success' | 'primary';
  runLimit: IRunLimitObj;
  selected?: boolean;
  onSelect?(val: boolean): void;
  onChange(val: IRunLimitObj): void;
}

const RunLimit = React.memo(
  ({height = '18.75rem', state, runLimit, onSelect, selected = false, onChange}: IRunLimitProps) => {
    const [canEdit, setCanEdit] = React.useState(true);
    const [showLicenseError, setShowLicenseError] = React.useState(false);

    const {ableTo} = useAppPermissions();

    React.useEffect(() => {
      const now = luxon.now();
      const card = luxon.fromSeconds(runLimit.endDate.seconds);

      const canIEdit = card > now && ableTo('LICENSED_TITLES_EDIT');
      setCanEdit(canIEdit);

      if (canIEdit) {
        setShowLicenseError(isNumber(runLimit.licenseFee) && (runLimit.licenseFee < -1 || runLimit.licenseFee > 5));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runLimit]);

    const licenseFeeChanged = val => onChange({...runLimit, licenseFee: val});
    const maxRunsChanged = val => onChange({...runLimit, maxRunsPeriod: val});

    return (
      <Box
        padding='small'
        borderRadius='0.5rem'
        height={height}
        borderColor={state ? state : 'transparent'}
        borderSize='0.1rem'
        borderStyle='solid'
        borderBottom={true}
        borderTop={true}
        borderRight={true}
        borderLeft={true}
        background='onyx'
        backgroundHover='cavern'
        id='runLimitBox'
      >
        <Stack space='xxxxsmall'>
          <Stack space='xlarge'>
            <Checkbox
              label={`${luxon
                .fromSeconds(runLimit.endDate.seconds)
                .setZone('America/Los_Angeles')
                .toFormat('MMM yyyy')}`}
              onChange={val => onSelect && onSelect(val)}
              state={!canEdit ? 'disabled' : ''}
              value={selected}
              id='endDate'
            />
            <FormItem label='Active Region' child='Paragraph'>
              <Paragraph>{runLimit.activeRegion.toUpperCase()}</Paragraph>
            </FormItem>
          </Stack>
          <Stack space='medium'>
            <FormItem label='Max Number of Runs Allowed Per Month' state={!canEdit ? 'disabled' : ''}>
              <TextInput
                id='maxRunsPeriod'
                type='number'
                value={runLimit.maxRunsPeriod}
                onChange={val => maxRunsChanged(val)}
                placeholder='Max Number of Runs Allowed'
              />
            </FormItem>
            <FormItem
              label='License Fee'
              state={!canEdit ? 'disabled' : showLicenseError ? 'error' : ''}
              helpText={showLicenseError ? 'Must be between -1 and 5' : ''}
              helpTextColor={showLicenseError ? 'error' : ''}
            >
              <TextInput
                id='licenseFee'
                type='number'
                value={runLimit.licenseFee}
                onChange={val => licenseFeeChanged(val)}
                placeholder='License Fee'
              />
            </FormItem>
          </Stack>
        </Stack>
      </Box>
    );
  },
);

RunLimit.displayName = 'RunLimit';
export default RunLimit;
