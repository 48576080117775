import {useHubLazyLoadProvider} from 'components/hubList/providers/HubLazyLoadProvider';
import {useHubSearchlLazyLoadProvider} from 'components/hubList/providers/HubSearchLazyLoadProvider';
import {IHubConfig, IHubConfigSearch} from 'models/hubConfigs';

export const useHubTableList = (
  isSearchActive: boolean,
): {
  items: IHubConfig[] | undefined;
  lazyLoad: () => void;
  isLazyLoading: boolean;
  isLoading: boolean;
  isError: boolean;
  reset: (resetForm?: boolean) => void;
  search: (params: IHubConfigSearch) => void;
  searchParams: IHubConfigSearch | undefined;
  totalCount: number;
} => {
  const {items, lazyLoad, isLazyLoading, isError, isLoading, reset, search, totalCount} = useHubLazyLoadProvider();
  const {
    items: searchItems,
    lazyLoad: searchLazyLoad,
    isLoading: isSearchLoading,
    isLazyLoading: isSearchLazyLoading,
    isError: isSearchError,
    reset: searchReset,
    search: searchSearch,
    searchParams,
    totalCount: searchTotalCount,
  } = useHubSearchlLazyLoadProvider();

  return {
    items: isSearchActive ? searchItems : items,
    isLoading: isSearchActive ? isSearchLoading : isLoading,
    lazyLoad: isSearchActive ? searchLazyLoad : lazyLoad,
    isLazyLoading: isSearchActive ? isSearchLazyLoading : isLazyLoading,
    isError: isSearchActive ? isSearchError : isError,
    reset: isSearchActive ? searchReset : reset,
    search: isSearchActive ? searchSearch : search,
    searchParams: isSearchActive ? searchParams : undefined,
    totalCount: isSearchActive ? searchTotalCount : totalCount,
  };
};
