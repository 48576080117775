import * as React from 'react';
import {Box, Button, Cluster, FormItem, TextInput, Textarea, Stack, useValidateForm} from '@pluto-tv/assemble';

import {ISeason} from 'models/series';

import {seriesSeasonValidator} from '../../validators';

const emptyModel: Partial<ISeason> = {
  number: undefined,
  synopsis: '',
};
export interface ISeasonFormProps {
  onCancel(): void;
  isNew?: boolean;
  value?: Partial<ISeason>;
  onSave: (savedObj: ISeason) => void;
  visible?: boolean;
}

const SeasonForm = React.memo(({isNew = false, onCancel, value, onSave, visible}: ISeasonFormProps) => {
  const {model, onBlur, form, setModel, state: formState, onChange} = useValidateForm<ISeason>(seriesSeasonValidator);

  React.useEffect(() => {
    if (visible) {
      setModel(isNew ? emptyModel : value!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, value, isNew]);

  return (
    <Box padding='small' background='charcoal' width='16.25rem'>
      <form id='seasonForm'>
        <Stack space='small'>
          <FormItem {...form.number} onBlur={() => onBlur('number')} permission={!isNew ? 'disabled' : ''}>
            <TextInput id='number' type='number' value={model.number} onChange={val => onChange('number', val)} />
          </FormItem>
          <FormItem {...form.synopsis} onBlur={() => onBlur('synopsis')}>
            <Textarea
              id='synopsis'
              minHeight='6.25rem'
              value={model.synopsis}
              onChange={val => onChange('synopsis', val)}
            />
          </FormItem>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='xxsmall'>
              <Button ghost={true} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={() => onSave(model as ISeason)}
                state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
              >
                {isNew ? '+ Add' : 'Update'}
              </Button>
            </Cluster>
          </Cluster>
        </Stack>
      </form>
    </Box>
  );
});

SeasonForm.displayName = 'SeasonForm';
export default SeasonForm;
