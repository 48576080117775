import * as crypto from 'crypto';
import {IAuthenticationResult} from './interfaces';

interface TokenData {
  token: IAuthenticationResult;
  expiration: Date;
}

export class TokenDictionary {
  private dictionary: {[key: string]: TokenData} = {};

  private calculateExpiration(duration: number): Date {
    return new Date(Date.now() + duration);
  }

  private generateKey(activeRegion: string, kidsOnly: boolean): string {
    const keyString = `${activeRegion}-${kidsOnly}`;
    return crypto.createHash('sha256').update(keyString).digest('hex');
  }

  public addToken(activeRegion: string, kidsOnly: boolean, token: IAuthenticationResult | null): string {
    // prevent adding empty
    if (!token) return '';

    // calculate key and expiration for new token
    const key = this.generateKey(activeRegion, kidsOnly);
    const expiration = this.calculateExpiration(token.refreshInSec);
    const tokenData = {token, expiration};

    // add token to dictionary
    this.dictionary[key] = tokenData;

    // return token
    return tokenData.token.sessionToken;
  }

  public getToken(activeRegion: string, kidsOnly: boolean): string {
    // calculate key based on given parameters
    const key = this.generateKey(activeRegion, kidsOnly);
    const tokenData = this.dictionary[key];

    // return token if found and not expired
    if (tokenData && new Date() < tokenData.expiration) {
      return tokenData.token.sessionToken;
    } else {
      return '';
    }
  }
}
