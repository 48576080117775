import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IRatingDescriptor} from 'models/ratingDescriptors';

export const ratingDescriptorsApi = createApi({
  reducerPath: 'ratingDescriptorsApi',
  baseQuery: baseQueryWithAuth,
  endpoints(builder) {
    return {
      find: builder.query<IRatingDescriptor[], void>({
        query: () => ({
          url: `rating-descriptors`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useFindQuery} = ratingDescriptorsApi;
