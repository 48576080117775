import * as React from 'react';
import {IDateRange, hmsToSeconds, isValEmpty, useCancellableSummon} from '@pluto-tv/assemble';
import {cloneDeep, isBoolean, throttle} from 'lodash-es';
import {DateTime as luxon} from 'luxon';

import {IClip} from 'models/clips';
import {IActiveRegion} from 'models/activeRegions';
import {IElasticSearchRes, IListPayload, IListQuery} from 'models/generic';

import {useUserRegions} from 'helpers/useUserRegions';

const HIGHEST_INT32 = 2147483647;

interface IParams {
  rowsPerPage?: 25 | 50 | 75 | 100;
  page?: number;
  sort?: string;
}

export interface IClipSearch
  extends Partial<
      Omit<
        IClip,
        | 'author'
        | 'isUsed'
        | 'promotional'
        | 'liveBroadcast'
        | 'activeRegion'
        | 'duration'
        | 'programmingType'
        | 'captionsRequired'
        | 'channelAssociation'
        | 'availabilityWindows'
        | 'url'
      >
    >,
    IListQuery {
  // Author needs to be simplified from an object to a string
  author?: string;
  // Certain fields are expected to be a string representation of a boolean in the API
  isUsed?: string;
  promotional?: string;
  liveBroadcast?: string;
  // Active Region needs to be an array
  activeRegion?: string[];
  // regionFilter -> territories
  territories?: string[];
  // Uploaded At
  timeWindow?: IDurationWindow;
  // Duration
  duration?: IDurationWindow;
  // distributeAs.AVOD -> distributeAsAVOD
  distributeAsAVOD?: string;
  // URL needs to be an array
  url?: string[];
  regionFilter?: string[];
  programmingType?: string;
  captionsRequired?: string;
  channelAssociation?: string;
  availabilityWindows?: string;
  plutoAvailsID?: string;
}

interface IDurationWindow {
  from: string | number;
  to: string | number;
}

export interface IClipDuration {
  start?: string;
  end?: string;
}

export interface IClipMetaSearch {
  uploadDate?: IDateRange;
  duration?: IClipDuration;
  window?: 'day' | 'week' | 'month';
}

interface IUseClipSearch {
  clips?: IListPayload<IClip>;
  isError: boolean;
  isFetching: boolean;
  rowsPerPage: 25 | 50 | 75 | 100;
  page: number;
  sort: string;
  clearSearch: (searchModel?: Partial<IClipSearch>, doSearch?: boolean) => void;
  search: (searchModel?: Partial<IClipSearch>, searchMeta?: IClipMetaSearch, params?: IParams) => void;
}

const transformReq = (
  model: Partial<IClipSearch>,
  page: number,
  perPage: 25 | 50 | 75 | 100,
  sort: string,
  searchMeta?: IClipMetaSearch,
  activeRegions?: IActiveRegion[],
): IClipSearch => {
  const searchObj = cloneDeep(model) as IClipSearch;

  if (!model.activeRegion) {
    searchObj.activeRegion = activeRegions?.map(ar => ar.code.toLowerCase());
  }

  if (isBoolean(model.isUsed)) {
    searchObj.isUsed = (model.isUsed as boolean).toString();
  }
  if (isBoolean(model.promotional)) {
    searchObj.promotional = (model.promotional as boolean).toString();
  }
  if (isBoolean(model.liveBroadcast)) {
    searchObj.liveBroadcast = (model.liveBroadcast as boolean).toString();
  }
  if (isBoolean(model.published)) {
    searchObj.published = (model.published as any).toString();
  }

  if (!isValEmpty(model.distributeAs?.AVOD)) {
    searchObj.distributeAsAVOD = model.distributeAs?.AVOD.toString();
    delete (searchObj as any).distributeAs;
  }

  if (model.regionFilter) {
    searchObj.territories = [...model.regionFilter];
    delete (searchObj as any).regionFilter;
  }

  if (searchMeta?.window || searchMeta?.uploadDate) {
    searchObj.timeWindow = {
      from: '',
      to: '',
    };

    const now = new Date();

    if (searchMeta.window) {
      switch (searchMeta.window) {
        case 'day':
          searchObj.timeWindow.from = luxon.fromJSDate(now).minus({days: 1}).toISO();
          searchObj.timeWindow.to = now.toISOString();
          break;
        case 'week':
          searchObj.timeWindow.from = luxon.fromJSDate(now).minus({weeks: 1}).toISO();
          searchObj.timeWindow.to = now.toISOString();
          break;
        case 'month':
          searchObj.timeWindow.from = luxon.fromJSDate(now).minus({months: 1}).toISO();
          searchObj.timeWindow.to = now.toISOString();
          break;
      }
    } else if (searchMeta.uploadDate) {
      searchObj.timeWindow.from = luxon
        .fromJSDate(new Date(searchMeta.uploadDate.start), {zone: 'America/Los_Angeles'})
        .startOf('day')
        .toISO();
      searchObj.timeWindow.to = luxon
        .fromJSDate(new Date(searchMeta.uploadDate.end!), {zone: 'America/Los_Angeles'})
        .endOf('day')
        .toISO();
    }
  }

  if (searchMeta?.duration) {
    let from = hmsToSeconds(searchMeta.duration?.start as string);
    let to = hmsToSeconds(searchMeta.duration?.end as string);

    // If `to` is invalid, make it a high number
    if (to === 0) {
      to = HIGHEST_INT32;
    }

    // Inverse the durations if they are in the wrong order
    if (from > to) {
      const to2 = from;
      from = to;
      to = to2;
    }

    searchObj.duration = {
      from,
      to,
    };
  }

  return {
    ...searchObj,
    sort,
    limit: perPage,
    offset: page * perPage,
  };
};

const defaultSearch: Partial<IClipSearch> = {
  provider: 'jwplatform',
};

const defaultSort = 'createdAt:desc';

export const useClipSearch = (): IUseClipSearch => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [clips, setClips] = React.useState<IListPayload<IClip>>();

  const [rowsPerPage, setRowsPerPage] = React.useState<25 | 50 | 75 | 100>(25);
  const [page, setPage] = React.useState<number>(0);
  const [sort, setSort] = React.useState<string>(defaultSort);

  const {activeRegions} = useUserRegions();
  const [summon] = useCancellableSummon();

  const clearSearch = async (searchModel: Partial<IClipSearch> = {}, doSearch = false): Promise<void> => {
    setPage(0);
    setSort(defaultSort);

    if (doSearch) {
      await search(searchModel, {}, {sort: defaultSort});
    }
  };

  const search = throttle(
    async (searchModel?: Partial<IClipSearch>, searchMeta?: IClipMetaSearch, params?: IParams) => {
      setIsFetching(true);
      const mySort = params?.sort ?? sort;
      const myRowsPerPage = params?.rowsPerPage ?? rowsPerPage;
      const myPage = params?.page ?? page;

      let cleanedModel: Partial<IClipSearch> = {};

      if (!searchModel || isValEmpty(searchModel)) {
        cleanedModel = cloneDeep(defaultSearch);
      } else {
        Object.keys(searchModel).forEach(key => {
          const fieldName: keyof IClipSearch = key as keyof IClipSearch;

          if (!isValEmpty(searchModel[fieldName])) {
            cleanedModel[fieldName] = cloneDeep(searchModel[fieldName]) as any;
          }
        });
      }

      const fullReq = transformReq(cleanedModel, myPage, myRowsPerPage, mySort, searchMeta, activeRegions);

      try {
        const res = await summon.post<IClipSearch, IElasticSearchRes<IClip>>('clips/search', fullReq);

        if (!res) {
          setClips({
            data: [],
            metadata: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              totalCount: 0,
            },
          });
        } else {
          const data = res.hits.hits.map(hit => hit._source);

          setClips({
            data,
            metadata: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              totalCount: res.hits.total,
            },
          });
        }
      } catch (e) {
        setIsError(true);
      } finally {
        setIsFetching(false);

        if (params?.page !== undefined) setPage(params.page);
        if (params?.rowsPerPage) setRowsPerPage(params.rowsPerPage);
        if (params?.sort) setSort(params.sort);
      }
    },
    150,
    {leading: false, trailing: true},
  );

  return {
    clips,
    isError,
    isFetching,
    rowsPerPage,
    page,
    sort,
    search,
    clearSearch,
  };
};

export const checkOriginUrlFileValidation = (value: string, fileExt: string): boolean =>
  value.split('?')[0].toLowerCase().includes(fileExt);
