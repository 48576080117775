import * as React from 'react';
import {IHubSearchData, ISearchData} from 'components/hooks/hub/useSearchHubData';

const DEFAULT_LIMIT = 50;

interface IUseHubSearchLazyLoadConfigContext<T, U> {
  items: T[];
  totalCount: number;
  isLazyLoading: boolean;
  lazyLoad: () => void;
  isError: boolean;
  isLoading: boolean;
  search: (params: U) => void;
  reset: (resetForm?: boolean) => void;
  searchParams: U;
}

// T type returned by the endpoint
// U params for the endpoint
// V useProvider hook
export const getHubSearchLazyLoadProviderContext = <
  T,
  U extends Record<string, unknown>,
  V extends IHubSearchData<ISearchData<T>, U>,
>(): {
  HubSearchContext: React.Context<IUseHubSearchLazyLoadConfigContext<T, U> | null>;
  HubSearchLazyLoadProvider: React.FC<{useProvider: () => V}>;
  useHubSearchLazyLoadProvider: () => IUseHubSearchLazyLoadConfigContext<T, U>;
} => {
  const HubSearchContext = React.createContext<IUseHubSearchLazyLoadConfigContext<T, U> | null>(null);

  return {
    HubSearchContext,
    HubSearchLazyLoadProvider: ({children, useProvider}) => {
      const offsetRef = React.useRef<number>(0);
      const paramsRef = React.useRef<U>({} as U);
      const hasMoreRef = React.useRef<boolean>(true);

      const [items, setItems] = React.useState<T[]>([]);
      const [totalCount, setTotalCount] = React.useState<number>(0);

      const {isFetching, isError, currentData: data, search: doSearch} = useProvider();

      const search = React.useCallback(
        (params?: U) => {
          hasMoreRef.current = true;
          paramsRef.current = params ?? ({} as any);
          offsetRef.current = 0;
          setItems([]);
          doSearch({...(params as any), offset: 0, limit: DEFAULT_LIMIT});
        },
        [doSearch],
      );

      const reset = React.useCallback((resetForm?: boolean) => {
        setItems([]);
        setTotalCount(0);
        const params = resetForm ? ({} as U) : paramsRef.current;
        offsetRef.current = 0;
        paramsRef.current = params;
        hasMoreRef.current = true;
      }, []);

      React.useEffect(() => {
        if (isFetching || !data) return;
        if (!data?.data?.length) {
          if (offsetRef.current === 0) {
            setItems([]);
            setTotalCount(0);
          }
          hasMoreRef.current = false;
        } else {
          setItems(prevData => {
            return offsetRef.current === 0 ? data?.data : [...prevData, ...data?.data];
          });

          if (data?.data?.length < DEFAULT_LIMIT) {
            hasMoreRef.current = false;
          }
        }

        setTotalCount(data?.totalCount);
      }, [data, isFetching]);

      const lazyLoad = React.useCallback(() => {
        if (!hasMoreRef.current || !items.length) return;

        offsetRef.current = offsetRef.current + DEFAULT_LIMIT;
        doSearch({...paramsRef.current, offset: offsetRef.current, limit: DEFAULT_LIMIT});
      }, [doSearch, items.length]);

      const value = React.useMemo(
        () => ({
          items,
          totalCount,
          isLazyLoading: isFetching && offsetRef.current > 0,
          lazyLoad,
          reset,
          isLoading: isFetching && offsetRef.current === 0,
          isError,
          search,
          searchParams: paramsRef.current,
        }),
        [isError, isFetching, items, lazyLoad, reset, search, totalCount],
      );

      return <HubSearchContext.Provider value={value}>{children}</HubSearchContext.Provider>;
    },
    useHubSearchLazyLoadProvider: () => {
      const context = React.useContext(HubSearchContext);
      if (!context) {
        throw new Error('useHubSearchLazyLoadProvider must be used within a HubSearchLazyLoadProvider');
      }
      return context;
    },
  };
};
