import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IContentRating} from 'models/contentRatings';

export const contentRatingsApi = createApi({
  reducerPath: 'contentRatingsApi',
  tagTypes: ['ContentRatings'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IContentRating[], void>({
      query: () => ({
        url: `ratings`,
        method: 'GET',
      }),
      providesTags: ['ContentRatings'],
    }),
  }),
});

export const {useFindQuery} = contentRatingsApi;
