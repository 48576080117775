import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Template,
  TextInput,
  Stack,
  useValidateForm,
  Select,
  ISelectOption,
} from '@pluto-tv/assemble';
import {IHubConfigSearch} from 'models/hubConfigs';
import {hubConfigSearchValidator} from 'views/programming/hubManager/hubs/validators';
import {useHubSearchlLazyLoadProvider} from 'components/hubList/providers/HubSearchLazyLoadProvider';
import {useUserRegions} from 'helpers/useUserRegions';

interface IHubSearchProps {
  onSearch: (search: IHubConfigSearch) => void;
  onClear: () => void;
  setIsExpanded: (value: boolean) => void;
  favoriteSearch?: React.ReactNode;
}

export const hubConfigTitleId = 'nameFilter';

const HubList = ({onSearch, onClear, setIsExpanded, favoriteSearch}: IHubSearchProps): React.ReactElement => {
  const {isLoading} = useHubSearchlLazyLoadProvider();
  const {activeRegions} = useUserRegions();

  const {
    model: searchModel,
    onChange: searchOnChange,
    form: searchForm,
    onBlur: searchOnBlur,
    reset: searchReset,
    setFields: setSearchFields,
    getValidation: searchGetValidation,
  } = useValidateForm<IHubConfigSearch>(hubConfigSearchValidator, 'ask');

  const handleSearch = async () => {
    const validation = await searchGetValidation();

    if (!validation.state.isValid) {
      return;
    }

    const {model} = validation;
    if (!model.name && !model.region) return;

    onSearch({
      ...model,
      name: model.name || '',
      region: model.region || [],
    });
  };

  const handleClear = () => {
    searchReset();
    onClear();
  };

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon icon='collapseleft' id='closeFilters' size='large' onClick={() => setIsExpanded(false)} />
          </Cluster>
          {favoriteSearch && <>{favoriteSearch}</>}
          <Divider color='graphite' />
        </Stack>
      </Template>
      <Template label='cover'>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <Stack space='xlarge'>
              <FormItem
                {...searchForm.name}
                onBlur={() => {
                  searchOnBlur('name');
                }}
              >
                <TextInput
                  id={hubConfigTitleId}
                  clearable={true}
                  placeholder='Title'
                  value={searchModel.name || ''}
                  onChange={val => {
                    searchOnChange('name', val);
                  }}
                />
              </FormItem>
            </Stack>
            <FormItem {...searchForm.region}>
              <Select
                clearable={true}
                multiselect={true}
                placeholder='Select Active Region'
                value={searchModel.region?.map(val => ({
                  label: '',
                  value: val,
                }))}
                onChange={val => {
                  setSearchFields({
                    region: (val as ISelectOption[])?.map(ar => ar.value as string),
                  });
                }}
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code,
                }))}
                predicate='value'
              />
            </FormItem>
          </Stack>
        </form>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='clearButton' ghost={true} onClick={handleClear} state={isLoading ? 'disabled' : ''}>
              Clear
            </Button>
            <Button id='searchButton' type='primary' onClick={handleSearch} state={isLoading ? 'thinking' : ''}>
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default HubList;
