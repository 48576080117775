import * as React from 'react';
import {trigger} from '@pluto-tv/assemble';

export function useMultiSelect<T>(
  items: T[],
  selectedItems: T[],
  setSelectedItems: (item: T[]) => void,
): [(indexes: number[]) => void] {
  const shiftPressedRef = React.useRef(false);
  const ctrlPressedRef = React.useRef(false);
  const lastItemClickedRef = React.useRef<number[]>([]);

  React.useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Shift') {
        shiftPressedRef.current = true;
      }
      if (event.key === 'Control' || event.key === 'Meta') {
        ctrlPressedRef.current = true;
      }
    };

    const handleKeyUp = event => {
      if (event.key === 'Shift') {
        shiftPressedRef.current = false;
      }
      if (event.key === 'Control' || event.key === 'Meta') {
        ctrlPressedRef.current = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleClickItems = React.useCallback(
    (indexes: number[]) => {
      const indexesSorted = indexes.sort();

      if (shiftPressedRef.current && lastItemClickedRef.current.length) {
        const selected: T[] = [...selectedItems];
        const lastItemClicked = lastItemClickedRef.current[lastItemClickedRef.current.length - 1];

        const start = Math.min(lastItemClicked, indexesSorted[0]);
        const end = Math.max(lastItemClicked, indexesSorted[indexesSorted.length - 1]);

        for (let i = start; i <= end; i++) {
          if (!selected.find(item => item === items[i])) {
            selected.push(items[i]);
          }
        }

        if (start !== lastItemClicked) selected.reverse();

        setSelectedItems(selected);
        trigger('selectedTimelineEntries', selected);
      } else if (ctrlPressedRef.current) {
        const itemAlreadySelected = selectedItems.some(item => indexes.find(i => items[i] === item));
        if (itemAlreadySelected) {
          const updatedSelectedItems = selectedItems.filter(item => !indexes.find(i => items[i] === item));
          setSelectedItems(updatedSelectedItems);
          trigger('selectedTimelineEntries', updatedSelectedItems);
          lastItemClickedRef.current = lastItemClickedRef.current.filter(item => !indexes.find(i => i === item));
        } else {
          const updatedSelectedItems = [...selectedItems, ...indexesSorted.map(i => items[i])];
          setSelectedItems(updatedSelectedItems);
          trigger('selectedTimelineEntries', updatedSelectedItems);
          lastItemClickedRef.current = [...lastItemClickedRef.current, ...indexesSorted].sort();
        }
      } else {
        if (selectedItems.length === indexes.length && selectedItems[0] === items[0]) {
          setSelectedItems([]);
          trigger('selectedTimelineEntries', []);
        } else {
          const updatedSelectedItems = indexesSorted.map(i => items[i]);
          setSelectedItems(updatedSelectedItems);
          trigger('selectedTimelineEntries', updatedSelectedItems);
          lastItemClickedRef.current = indexesSorted;
        }
      }
    },
    [items, selectedItems, setSelectedItems],
  );

  return [handleClickItems];
}
