import * as React from 'react';

import {ISelectOption, Select} from '@pluto-tv/assemble';
import {ICarouselConfigSearchQuery, useLazySearchByNameQuery} from 'features/carouselConfigs/carouselConfigsApi';

interface ICarouselConfigAutocompleteProps {
  onSelect: (value?: ISelectOption) => void;
  activeRegions: string[];
  disabledIds?: string[];
}

const CarouselConfigAutocomplete = ({
  onSelect,
  activeRegions,
  disabledIds,
}: ICarouselConfigAutocompleteProps): React.ReactElement => {
  const [searchCarousel] = useLazySearchByNameQuery();
  const [carouselValue, setCarouselValue] = React.useState<ISelectOption | null>(null);

  const handleChange = (value?: ISelectOption) => {
    setCarouselValue(value || null);
    onSelect(value);
  };

  const handleSearch = async (search: string): Promise<ISelectOption[]> => {
    if (!activeRegions.length || !search) {
      return [];
    }

    const params: ICarouselConfigSearchQuery = {
      name: search,
      limit: 50,
      offset: 0,
    };

    try {
      const response = await searchCarousel(params).unwrap();
      if (!response.data || !response.data.length) {
        return [
          {
            label: 'No Results Found',
            value: '',
            disabled: true,
          },
        ];
      }
      return (
        response?.data?.map(config => ({
          label: `${config.name}${config.activeRegion ? ' (' + config.activeRegion[0].toUpperCase() + ')' : ''}`,
          value: config.id,
          disabled: disabledIds?.includes(config.id),
        })) || []
      );
    } catch (error) {
      return [
        {
          label: 'No Results Found',
          value: '',
          disabled: true,
        },
      ];
    }
  };

  return (
    <Select
      clearable={true}
      predicate='value'
      placeholder='Search for carousel'
      searchPlaceholder='Search for carousel'
      searchable={true}
      sortField='label'
      value={{label: carouselValue?.label || '', value: carouselValue?.value || ''}}
      onSearch={handleSearch}
      options={[]}
      autoComplete={true}
      onChange={handleChange}
      id='carouselConfig'
    />
  );
};

CarouselConfigAutocomplete.displayName = 'CarouselConfigAutocomplete';
export default CarouselConfigAutocomplete;
