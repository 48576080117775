import {useCarouselLazyLoadProvider} from 'components/carouselList/providers/CarouselLazyLoadProvider';
import {useCarouseSearchlLazyLoadProvider} from 'components/carouselList/providers/CarouselSearchLazyLoadProvider';
import {ICarouselConfig, ICarouselConfigSearch} from 'models/carouselConfigs';

export const useCarouselTableList = (
  isSearchActive: boolean,
): {
  items: ICarouselConfig[] | undefined;
  totalCount: number;
  lazyLoad: (params?: ICarouselConfigSearch) => void;
  isLazyLoading: boolean;
  isLoading: boolean;
  isError: boolean;
  reset: (resetForm?: boolean) => void;
  searchParams?: ICarouselConfigSearch;
  search: (params: ICarouselConfigSearch) => void;
} => {
  const {items, totalCount, lazyLoad, isLazyLoading, isError, isLoading, reset, search} = useCarouselLazyLoadProvider();
  const {
    items: searchItems,
    totalCount: searchTotalCount,
    lazyLoad: searchLazyLoad,
    isLoading: isSearchLoading,
    isLazyLoading: isSearchLazyLoading,
    isError: isSearchError,
    reset: searchReset,
    searchParams,
    search: carouselSearch,
  } = useCarouseSearchlLazyLoadProvider();

  return {
    items: isSearchActive ? searchItems : items,
    totalCount: isSearchActive ? searchTotalCount : totalCount,
    isLoading: isSearchActive ? isSearchLoading : isLoading,
    lazyLoad: isSearchActive ? searchLazyLoad : lazyLoad,
    isLazyLoading: isSearchActive ? isSearchLazyLoading : isLazyLoading,
    isError: isSearchActive ? isSearchError : isError,
    reset: isSearchActive ? searchReset : reset,
    searchParams: isSearchActive ? searchParams : undefined,
    search: isSearchActive ? carouselSearch : search,
  };
};
