import * as React from 'react';

import {usePreferencesQuery, useUpdatePreferencesMutation} from 'features/users/usersApi';
import {
  IUserChannelsSearch,
  IUserFeaturedGroupsSearch,
  IUserClipSearch,
  IUserEpisodeSearch,
  IUserLicensedTitleSearch,
  IUserSeriesSearch,
  IUserRunLimitTrackerSearch,
  IUserVodCollectionSearch,
  IUserCampaignSearch,
  IUserHubConfigSearch,
  IUserCarouselConfigSearch,
} from 'models/users';
import {SearchType} from 'models/users';

interface IUsePreferences<T> {
  isFetching: boolean;
  isError: boolean;
  preferences: any;
  clipSearches: IUserClipSearch[];
  episodeSearches: IUserEpisodeSearch[];
  seriesSearches: IUserSeriesSearch[];
  channelsSearches: IUserChannelsSearch[];
  featuredGroupsSearches: IUserFeaturedGroupsSearch[];
  licensedTitleSearches: IUserLicensedTitleSearch[];
  runLimitTrackerSearches: IUserRunLimitTrackerSearch[];
  campaignSearches: IUserCampaignSearch[];
  updateSavedSearches: (searchType: SearchType, searchList: T[]) => Promise<void>;
  vodCollectionsSearches: IUserVodCollectionSearch[];
  hubConfigsSearches: IUserHubConfigSearch[];
  carouselConfigsSearches: IUserCarouselConfigSearch[];
}

export const usePreferences = <T>(): IUsePreferences<T> => {
  const {isFetching, isError, data: preferences} = usePreferencesQuery();
  const [clipSearches, setClipSearches] = React.useState<IUserClipSearch[]>([]);
  const [episodeSearches, setEpisodeSearches] = React.useState<IUserEpisodeSearch[]>([]);
  const [seriesSearches, setSeriesSearches] = React.useState<IUserSeriesSearch[]>([]);
  const [channelsSearches, setChannelsSearches] = React.useState<IUserChannelsSearch[]>([]);
  const [featuredGroupsSearches, setFeaturedGroupsSearches] = React.useState<IUserFeaturedGroupsSearch[]>([]);
  const [licensedTitleSearches, setLicensedTitleSearches] = React.useState<IUserLicensedTitleSearch[]>([]);
  const [runLimitTrackerSearches, setRunLimitTrackerSearches] = React.useState<IUserRunLimitTrackerSearch[]>([]);
  const [vodCollectionsSearches, setVodCollectionsSearches] = React.useState<IUserVodCollectionSearch[]>([]);
  const [campaignSearches, setCampaignSearches] = React.useState<IUserCampaignSearch[]>([]);
  const [hubConfigsSearches] = React.useState<IUserHubConfigSearch[]>([]);
  const [carouselConfigsSearches] = React.useState<IUserCarouselConfigSearch[]>([]);

  React.useEffect(() => {
    if (!preferences) {
      return;
    }

    if (preferences?.searches?.clips) {
      setClipSearches(preferences.searches.clips);
    }

    if (preferences?.searches?.episodes) {
      setEpisodeSearches(preferences.searches.episodes);
    }

    if (preferences?.searches?.series) {
      setSeriesSearches(preferences.searches.series);
    }

    if (preferences?.searches?.channels) {
      setChannelsSearches(preferences.searches.channels);
    }

    if (preferences?.searches?.featuredGroups) {
      setFeaturedGroupsSearches(preferences.searches.featuredGroups);
    }

    if (preferences?.searches?.licensedTitle) {
      setLicensedTitleSearches(preferences.searches.licensedTitle);
    }

    if (preferences?.searches?.runLimitTracker) {
      setRunLimitTrackerSearches(preferences.searches.runLimitTracker);
    }

    if (preferences?.searches?.vodCollections) {
      setVodCollectionsSearches(preferences.searches.vodCollections);
    }

    if (preferences?.searches?.campaigns) {
      setCampaignSearches(preferences.searches.campaigns);
    }
  }, [preferences]);

  const [updatePreferences] = useUpdatePreferencesMutation();

  const updateSavedSearches = async (searchType: SearchType = 'clips', searchList: T[]) => {
    try {
      await updatePreferences({
        ...preferences,
        searches: {
          ...preferences?.searches,
          [searchType]: searchList,
        },
      });
    } catch (e) {}
  };

  return {
    clipSearches,
    episodeSearches,
    seriesSearches,
    channelsSearches,
    featuredGroupsSearches,
    licensedTitleSearches,
    runLimitTrackerSearches,
    campaignSearches,
    isError,
    isFetching,
    preferences,
    updateSavedSearches,
    vodCollectionsSearches,
    hubConfigsSearches,
    carouselConfigsSearches,
  };
};
