import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IBackendInfo} from 'models/backendInfo';

export const backendInfoApi = createApi({
  reducerPath: 'backendInfoApi',
  baseQuery: baseQueryWithAuth,
  endpoints(builder) {
    return {
      info: builder.query<IBackendInfo, void>({
        query: () => ({
          url: `healthcheck/info`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useInfoQuery} = backendInfoApi;
