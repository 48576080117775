import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IChannelCategory} from 'models/channelCategories';

export const channelCategoriesApi = createApi({
  reducerPath: 'channelCategoriesApi',
  tagTypes: ['ChannelCategories'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IChannelCategory[], void>({
      query: () => ({
        url: `channel-categories`,
        method: 'GET',
      }),
      providesTags: ['ChannelCategories'],
    }),
  }),
});

export const {useFindQuery} = channelCategoriesApi;
