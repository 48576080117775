import * as React from 'react';

import {useCancellableSummon} from '@pluto-tv/assemble';
import {IAuthenticationResult, ICarouselBuilderResult, IServiceCarouselBuilderHook} from './interfaces';
import {Urls} from './constants';
import {TokenDictionary} from './tokenDictionary';
import {useLazyFindContentQuery} from 'features/carouselContent/carouselContentApi';
import {useCarouselContentCache} from 'views/programming/hubManager/hooks/useCarouselContentCache';

export const useServiceCarouselBuilder = (): IServiceCarouselBuilderHook => {
  const [summon] = useCancellableSummon();
  const tokenDictRef = React.useRef(new TokenDictionary());
  const [fetchContent] = useLazyFindContentQuery();
  const {cachedCarouselContents, cachedIds} = useCarouselContentCache();

  const buildParams = (activeRegion: string, kidsOnly: boolean) => {
    let params = `activeRegion=${activeRegion}`;
    if (kidsOnly) {
      params += `&constraints=kids-only`;
    }
    return params;
  };

  const authenticate = async (activeRegion: string, kidsOnly: boolean): Promise<IAuthenticationResult | null> => {
    const params = buildParams(activeRegion, kidsOnly);
    return await summon.get<void, IAuthenticationResult>(`${Urls.Authentication}?${params}`);
  };

  const getCarousel = async (carouselIds: string[], token: string): Promise<ICarouselBuilderResult[]> => {
    // The service-carousel-builder endpoint supports up to 6 ids in bulk.
    // Doing this to divide the list in a lists of 6 ids to make the api calls
    const idsCopy = [...carouselIds];

    if (!idsCopy.length) return cachedCarouselContents;

    const slicedList = [idsCopy.splice(0, 6)];

    while (idsCopy.length > 0) {
      slicedList.push(idsCopy.splice(0, 6));
    }

    const promises = slicedList.map(idsList => fetchContent({ids: idsList, token}).unwrap());
    const promiseResponse = await Promise.all(promises);

    const response = promiseResponse.reduce(
      (tmp: any, res: any) => [...tmp, ...res.carousels],
      [...cachedCarouselContents],
    ) as ICarouselBuilderResult[];

    return response;
  };

  const getValidToken = async (activeRegion: string, kidsOnly: boolean): Promise<string> => {
    return (
      tokenDictRef.current.getToken(activeRegion, kidsOnly) ||
      tokenDictRef.current.addToken(activeRegion, kidsOnly, await authenticate(activeRegion, kidsOnly))
    );
  };

  const executeQuery = async (
    activeRegion: string,
    kidsOnly: boolean,
    carouselIds: string[],
  ): Promise<ICarouselBuilderResult[] | null> => {
    const idsCopy = carouselIds.filter(id => !cachedIds.includes(id));
    if (!idsCopy.length) return cachedCarouselContents;

    try {
      const token = await getValidToken(activeRegion, kidsOnly);
      return await getCarousel(idsCopy, token);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // If the token is expired or invalid, re-authenticate and retry
        const newToken = tokenDictRef.current.addToken(
          activeRegion,
          kidsOnly,
          await authenticate(activeRegion, kidsOnly),
        );
        return await getCarousel(idsCopy, newToken);
      }
      throw error; // Re-throw the error if it's not a 401
    }
  };

  return {
    executeQuery,
    tokenDictionary: tokenDictRef.current,
  };
};
