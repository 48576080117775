import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {orderBy, filter} from 'lodash-es';
import {IPartner} from 'models/partners';

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  tagTypes: ['Partners'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IPartner[], void>({
      query: () => ({
        url: `partners`,
        method: 'GET',
      }),
      providesTags: ['Partners'],
      transformResponse: (data: IPartner[]) =>
        orderBy(
          filter(data, partner => !!partner.name),
          partner => partner.name.toLowerCase(),
        ),
    }),
  }),
});

export const {useFindQuery} = partnersApi;
