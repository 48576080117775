import {isValEmpty} from '@pluto-tv/assemble';
import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IEpisode, IEpisodeListResult, IEpisodeProgramDetails, IListEpisodeQuery} from 'models/episodes';
import {IListPayload} from 'models/generic';

const buildSearchQueryString = (params: IListEpisodeQuery): string => {
  const q = Object.keys(params).map(k => {
    // season, sort first by season -> then by number
    if (k === 'sort' && params[k] && params[k]?.startsWith('season:') && params.seriesID) {
      const sortDir = params[k]!.split(':')[1];

      if (!sortDir) {
        return '';
      }

      return `sort=season:${sortDir}&sort=number:${sortDir}`;
    }
    // status, sort first by status ->then by season:asc -> then by number:asc
    if (k === 'sort' && params[k] && params[k]?.startsWith('status:') && params.seriesID) {
      const sortDir = params[k]!.split(':')[1];

      if (!sortDir) {
        return '';
      }

      return `sort=status:${sortDir}&sort=season:asc&sort=number:asc`;
    }
    if (Array.isArray(params[k])) {
      return params[k]
        .filter(v => v)
        .map(v => `${k}=${encodeURIComponent(v)}`)
        .join('&');
    }
    if (!isValEmpty(params[k])) {
      return `${k}=${encodeURIComponent(params[k])}`;
    }

    return '';
  });
  return q.filter(f => f !== '').join('&');
};

export const episodesApi = createApi({
  reducerPath: 'episodesApi',
  tagTypes: ['Episodes'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IListPayload<IEpisodeListResult>, IListEpisodeQuery>({
      providesTags: ['Episodes'],
      query: params => ({
        url: `episodes?${buildSearchQueryString(params)}`,
        method: 'GET',
      }),
    }),
    findById: builder.query<IEpisode, string>({
      query: (id: string) => ({
        url: `episodes/${id}`,
        method: 'GET',
      }),
      providesTags: ['Episodes'],
    }),
    insert: builder.mutation<IEpisode, Partial<IEpisode>>({
      query: episode => ({
        url: `episodes`,
        method: 'POST',
        body: episode,
      }),
      // invalidatesTags: [ 'Episodes'],
    }),
    update: builder.mutation<
      IEpisode,
      {id: string; episode: Partial<IEpisode>; fields: string[]; acceptWarnings: boolean}
    >({
      query: ({id, episode, fields, acceptWarnings}) => ({
        url: `episodes/${id}`,
        method: 'PUT',
        body: {
          id: id,
          episodeParams: episode,
          updateFields: fields,
          acceptWarnings: acceptWarnings,
        },
      }),
      invalidatesTags: ['Episodes'],
    }),
    isValid: builder.query<string, string>({
      query: id => ({
        url: `episodes/${id}/isValid`,
        method: 'GET',
      }),
    }),
    bulk: builder.query<IEpisodeProgramDetails[], string[]>({
      query: episodesIds => ({
        url: `episodes/bulk`,
        method: 'POST',
        body: {ids: episodesIds},
      }),
    }),
  }),
});

export const {
  useFindQuery,
  useLazyFindQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useIsValidQuery,
  useBulkQuery,
  useLazyBulkQuery,
} = episodesApi;
