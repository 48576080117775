import * as React from 'react';
import {Box, Button, Cluster, Heading, Icon, Paragraph, Sidebar, Stack} from '@pluto-tv/assemble';

export interface IDeleteConfirmation {
  message?: string;
  cancelButtonFunction(): void;
  proceedButtonFunction(): void;
}

const DeleteConfirmation = React.memo(
  ({
    message = 'Are you sure you want to delete this item?',
    cancelButtonFunction,
    proceedButtonFunction,
  }: IDeleteConfirmation) => {
    return (
      <Box id='areYouSureContent' padding='small' background='charcoal'>
        <Sidebar gap='large'>
          <Icon icon='warning' size='xxxlarge' />
          <Box paddingTop='xsmall'>
            <Stack space='large'>
              <Heading level='h3'>Are you sure?</Heading>
              <Paragraph id='areYouSureContent'>{message}</Paragraph>
              <Cluster justify='space-between'>
                <div></div>
                <Cluster space='small'>
                  <Button id='cancelButton' ghost={true} onClick={() => cancelButtonFunction()}>
                    Cancel
                  </Button>
                  <Button id='yesProceedButton' type='delete' onClick={() => proceedButtonFunction()}>
                    Yes, Proceed
                  </Button>
                </Cluster>
              </Cluster>
            </Stack>
          </Box>
        </Sidebar>
      </Box>
    );
  },
);

DeleteConfirmation.displayName = 'DeleteConfirmation';
export default DeleteConfirmation;
