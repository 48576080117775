import {getHubSearchLazyLoadProviderContext} from 'components/providers/hub/getHubSearchLazyLoadProvider';
import {IHubSearchData, ISearchData} from 'components/hooks/hub/useSearchHubData';
import {IHubConfig, IHubConfigSearch} from 'models/hubConfigs';

const {useHubSearchLazyLoadProvider: useHubLazyLoadProvider, HubSearchLazyLoadProvider: HubLazyLoadProvider} =
  getHubSearchLazyLoadProviderContext<
    IHubConfig,
    IHubConfigSearch,
    IHubSearchData<ISearchData<IHubConfig>, IHubConfigSearch>
  >();
export {useHubLazyLoadProvider, HubLazyLoadProvider};
