import {useAppDispatch as useDispatch} from 'app/store/hooks';
import {useEffect} from 'react';

import {createApi} from '@reduxjs/toolkit/query/react';
import {isValEmpty} from '@pluto-tv/assemble';
import {IListSeriesQuery, ISeries, ISeriesListResult} from 'models/series';
import {IListPayload} from 'models/generic';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';

const buildSearchQueryString = (params: IListSeriesQuery): string => {
  const q = Object.keys(params).map(k => {
    if (Array.isArray(params[k])) {
      return params[k]
        .filter(v => v)
        .map(v => `${k}=${encodeURIComponent(v)}`)
        .join('&');
    }
    if (!isValEmpty(params[k])) {
      return `${k}=${encodeURIComponent(params[k])}`;
    }

    return '';
  });
  return q.filter(f => f !== '').join('&');
};

export const seriesApi = createApi({
  reducerPath: 'seriesApi',
  tagTypes: ['Series'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IListPayload<ISeriesListResult>, IListSeriesQuery>({
      providesTags: ['Series'],
      query: params => ({
        url: `series?${buildSearchQueryString(params)}`,
        method: 'GET',
      }),
    }),
    findById: builder.query<ISeries, string>({
      query: (id: string) => ({
        url: `series/${id}`,
        method: 'GET',
      }),
      providesTags: ['Series'],
    }),
    insert: builder.mutation<ISeries, Partial<ISeries>>({
      query: series => ({
        url: `series`,
        method: 'POST',
        body: series,
      }),
    }),
    update: builder.mutation<ISeries, {id: string; series: Partial<ISeries>; fields: string[]}>({
      query: ({id, series, fields}) => ({
        url: `series/${id}`,
        method: 'PUT',
        body: {
          id,
          seriesParams: series,
          updateFields: fields,
        },
      }),
      invalidatesTags: ['Series'],
    }),
    delete: builder.mutation<ISeries, string>({
      query: (id: string) => ({
        url: `series/${id}`,
        method: 'DELETE',
      }),
    }),
    isValid: builder.query<string, string>({
      query: id => ({
        url: `series/${id}/isvalid`,
        method: 'GET',
      }),
    }),
  }),
});

const useDeleteMutation = (invalidateTimeout?: number): [(id: string) => any] => {
  const dispatch = useDispatch();
  const [deleteSeries, {isSuccess}] = seriesApi.useDeleteMutation();

  useEffect(() => {
    if (isSuccess) {
      if (invalidateTimeout === undefined) {
        dispatch(seriesApi.util.invalidateTags(['Series']));
        return;
      }

      setTimeout(() => {
        dispatch(seriesApi.util.invalidateTags(['Series']));
      }, invalidateTimeout);
    }
  }, [isSuccess, dispatch, invalidateTimeout]);

  return [deleteSeries];
};

const useInsertMutation = (invalidateTimeout?: number): [(series: Partial<ISeries>) => any] => {
  const dispatch = useDispatch();
  const [insertSeries, {isSuccess}] = seriesApi.useInsertMutation();

  useEffect(() => {
    if (isSuccess) {
      if (invalidateTimeout === undefined) {
        dispatch(seriesApi.util.invalidateTags(['Series']));
        return;
      }

      setTimeout(() => {
        dispatch(seriesApi.util.invalidateTags(['Series']));
      }, invalidateTimeout);
    }
  }, [isSuccess, dispatch, invalidateTimeout]);

  return [insertSeries];
};

const {useFindQuery, useLazyFindQuery, useFindByIdQuery, useLazyFindByIdQuery, useUpdateMutation, useIsValidQuery} =
  seriesApi;

export {
  useFindQuery,
  useLazyFindQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useUpdateMutation,
  useIsValidQuery,
  // Custom hooks that allow you to pass in an optional timeout number to delay invalidation.
  // Sometimes, we need to wait for MongoDB index processing before invalidating.
  useDeleteMutation,
  useInsertMutation,
};
