import {getHubSearchLazyLoadProviderContext} from 'components/providers/hub/getHubSearchLazyLoadProvider';
import {IHubSearchData, ISearchData} from 'components/hooks/hub/useSearchHubData';
import {ICarouselConfig, ICarouselConfigSearch} from 'models/carouselConfigs';

const {
  useHubSearchLazyLoadProvider: useCarouseSearchlLazyLoadProvider,
  HubSearchLazyLoadProvider: CarouselSearchLazyLoadProvider,
} = getHubSearchLazyLoadProviderContext<
  ICarouselConfig,
  ICarouselConfigSearch,
  IHubSearchData<ISearchData<ICarouselConfig>, ICarouselConfigSearch>
>();

export {useCarouseSearchlLazyLoadProvider, CarouselSearchLazyLoadProvider};
