import * as React from 'react';
import {IApi, ISource} from 'models/carouselConfigs';
import {useCancellableSummon} from '@pluto-tv/assemble';
import {policyMappings} from 'components/carouselList/helpers/SourceServiceHelper';
import {ICarouselConfigSourceService} from 'models/carouselConfigs';
import {useFindQuery as useMaingCategoriesFindQuery} from 'features/mainCategories/mainCategoriesApi';
import {IMainCategory} from 'models/mainCategories';

interface ApiParams {
  [key: string]: string;
}

const getAssociatedCarouselById = async (
  fetch: {get: (url: string) => Promise<any>},
  url: string,
  getName: (response: any) => string,
  getId: (response: any) => string,
): Promise<{name: string; id: string} | null> => {
  const response = await fetch.get(url);
  return {name: getName(response), id: getId(response)};
};

const getVodAssociatedCarousel = async (categoryId: string, fetch: {get: (url: string) => Promise<any>}) => {
  return getAssociatedCarouselById(
    fetch,
    `/vod/${categoryId}`,
    response => `${response.name.trim()} (${response.activeRegion.toUpperCase()})`,
    response => response.id,
  );
};

const getChannelAssociatedCarousel = async (categoryId: string, fetch: {get: (url: string) => Promise<any>}) => {
  return getAssociatedCarouselById(
    fetch,
    `/channels/${categoryId}`,
    response => `${response.name.trim()} (${response.activeRegion.toUpperCase()})`,
    response => response.id,
  );
};

const getMovieAssociatedCarousel = async (categoryId: string, fetch: {get: (url: string) => Promise<any>}) => {
  return getAssociatedCarouselById(
    fetch,
    `/episodes/${categoryId}`,
    response => `${response.name.trim()} (${response.activeRegion.toUpperCase()})`,
    response => response._id,
  );
};

const getTvShowsAssociatedCarousel = async (categoryId: string, fetch: {get: (url: string) => Promise<any>}) => {
  return getAssociatedCarouselById(
    fetch,
    `/series/${categoryId}`,
    response => `${response.name.trim()} (${response.activeRegion.toUpperCase()})`,
    response => response.id,
  );
};

const getMediaLibraryAssociatedCarousel = async (
  categoryId: string,
  _fetch: unknown,
  data: {mainCategories: IMainCategory[]},
) => {
  const category = (data.mainCategories || []).find((category: any) => category.id === categoryId);
  if (!category) return null;
  return {name: `${category.name.trim()} (${category.activeRegion.toUpperCase()})`, id: category.id};
};

export const urlByIdMapping = {
  mediacatalog: {
    'main-categories': {idName: 'mainCategoryId', fetch: getMediaLibraryAssociatedCarousel},
  },
  vod: {
    categories: {idName: 'vodCategoryId', fetch: getVodAssociatedCarousel},
  },
  recommender: {
    'similar-channels': {idName: 'channelId', fetch: getChannelAssociatedCarousel},
    'similar-movies': {idName: 'episodeId', fetch: getMovieAssociatedCarousel},
    'similar-tvshows': {idName: 'seriesId', fetch: getTvShowsAssociatedCarousel},
  },
} as const;

export const getSourceServiceRow = async (
  api: IApi,
  fetch: {get: (url: string) => Promise<any>},
  data: {mainCategories: IMainCategory[] | undefined},
): Promise<ICarouselConfigSourceService> => {
  const [source, service] = api.apiService.split('#');
  const {idName, fetch: fetchAssociatedCarousel} = urlByIdMapping[source]?.[service] || {
    idName: '',
    fetch: null,
  };

  const baseResponse: ICarouselConfigSourceService = {
    source: source as ISource,
    service,
    policy: policyMappings[api.policy || 'append'],
    position: api.position || 0,
  };

  if (!fetchAssociatedCarousel || !fetch || !idName || !api.apiParams) {
    return baseResponse;
  }

  const id = (api.apiParams as ApiParams)[idName] || (api.apiParams as ApiParams).categoryId;
  if (!id) {
    return baseResponse;
  }

  if (api.apiParams.mainCategoryName) {
    return {...baseResponse, associatedCarousel: {name: api.apiParams.mainCategoryName, id}};
  }

  try {
    const response = await fetchAssociatedCarousel(id, fetch, data);
    return {
      ...baseResponse,
      associatedCarousel: {...response, id},
    };
  } catch (e) {
    return {...baseResponse, associatedCarousel: 'error'};
  }
};

interface SearchState {
  data: ICarouselConfigSourceService[];
  isLoading: boolean;
  isError: boolean;
}

const searchReducer = (prevState: SearchState, data: Partial<SearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

export const useGetSourceServiceRows = (
  apis: IApi[],
): {data: ICarouselConfigSourceService[]; isLoading: boolean; isError: boolean} => {
  const shouldFetchMainCategories = apis.some(api =>
    ((api.apiService || '').split('#')[1] || '').includes('main-categories'),
  );
  const {data: mainCategories, isLoading: isMainCategoriesLoading} = useMaingCategoriesFindQuery(undefined, {
    skip: !shouldFetchMainCategories,
  });

  const [summon] = useCancellableSummon();

  const [query, queryDispatch] = React.useReducer<React.Reducer<SearchState, Partial<SearchState>>>(searchReducer, {
    data: [],
    isLoading: false,
    isError: false,
  });

  const {data: rows, isLoading, isError} = query;

  const fetchRows = React.useCallback(async () => {
    const promises = apis.map(api =>
      getSourceServiceRow(api, summon, {
        mainCategories: mainCategories || [],
      }),
    );
    const results = await Promise.all(promises);
    return results;
    // We should keep the eslint line as the summon object is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis, mainCategories]);

  React.useEffect(() => {
    if (!apis.length) return;
    queryDispatch({isLoading: true, isError: false});
    fetchRows()
      .then(response => {
        queryDispatch({isLoading: false, data: response});
      })
      .catch(() => {
        queryDispatch({isLoading: false, isError: true});
      });
  }, [apis.length, fetchRows]);

  return {data: rows, isLoading: isLoading || isMainCategoriesLoading, isError};
};
