import * as React from 'react';
import {IChannelQueueParams, IChannelQueueQuery} from 'models/channelQueue';
import {useMemoryQueueApi} from 'views/programming/channel/edit/catalog/hooks/useMemoryQueueApi';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import {IListPayload} from 'models/generic';

type ChannelCatalogQueueReturn = {
  queueItems: IChannelCatalogItemWithState[];
  duration: number;
  setQueueItems: React.Dispatch<React.SetStateAction<IChannelCatalogItemWithState[]>>;
  totalItems: number;
  isError: boolean;
  isLazyLoading: boolean;
  isLoading: boolean;
  handleLazyLoad: () => void;
  handleSorting: (sort: string) => void;
  fetch: (searchParams?: Partial<IChannelQueueQuery>) => void;
  shuffle: () => void;
  addItems: (index: number, items: IChannelCatalogItemWithState[]) => void;
  movedToQueue: IChannelCatalogItemWithState[];
  refetchDuration: () => void;
  moveAlong: (fromIndex: number[], toIndex: number) => void;
  sort: string;
  removeItems: (items: IChannelCatalogItemWithState[]) => void;
  refetch: () => void;
  movedToQueueDuringFilter: IChannelCatalogItemWithState[];
  handleSearching: (formModel: Partial<IChannelQueueQuery>) => void;
};

export const defaultParams: IChannelQueueParams = {
  sort: ['order:asc'],
  offset: 0,
  limit: 50000,
};

const getSortString = (sort: string = defaultParams?.sort!.join('&') as string) => {
  return sort.split('&')[0];
};

const getSplittedSort = (sort: string) => {
  const splitedSort = sort.split(':');
  return splitedSort;
};

export const useChannelCatalogQueue = (isSearchActive: boolean, channelId?: string): ChannelCatalogQueueReturn => {
  const [movedToQueue, setMovedToQueue] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [queueItems, setQueueItems] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [params, setParams] = React.useState<Partial<IChannelQueueQuery>>(defaultParams);
  const [loadingFirstPage, setLoadingFirstPage] = React.useState<boolean>(true);
  const [movedToQueueDuringFilter, setMovedToQueueDuringFilter] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const [duration, setDuration] = React.useState<number>(0);

  // this ref stores the previous search params to be used in lazy loading
  const searchParamsRef = React.useRef<Partial<IChannelQueueQuery>>(defaultParams);
  const dataRef = React.useRef<IListPayload<IChannelCatalogItemWithState>>();

  React.useEffect(() => {
    if (!isSearchActive) {
      setMovedToQueueDuringFilter([]);
    }
  }, [isSearchActive]);

  const {
    data,
    isError,
    isLoading,
    fetchQueue,
    shuffle,
    addItems: addToMemoryQueue,
    moveAlong: moveAlongQueue,
    pristineQueue,
    removeItemsById,
    refetch: refetchQueue,
    refetchDuration,
  } = useMemoryQueueApi(channelId || '');

  React.useEffect(() => {
    fetchQueue(params);
  }, [fetchQueue, params]);

  React.useEffect(() => {
    if (data) {
      dataRef.current = data;
      setQueueItems(prevItems => (data.metadata.offset === 0 ? data.data : [...prevItems, ...data.data]));
    }
  }, [data]);

  React.useEffect(() => {
    setTotalItems(data?.metadata.totalCount || 0);
  }, [data?.metadata.totalCount]);

  React.useEffect(() => {
    setDuration(data?.metadata?.duration || 0);
  }, [data?.metadata?.duration]);

  const refetch = React.useCallback(() => {
    setLoadingFirstPage(true);
    setMovedToQueue([]);
    setMovedToQueueDuringFilter([]);
    refetchQueue();
  }, [refetchQueue]);

  const fetch = React.useCallback((searchParams?: Partial<IChannelQueueQuery>) => {
    setLoadingFirstPage(true);
    setQueueItems([]);
    searchParamsRef.current = searchParams || {};
    setParams({...defaultParams, ...searchParams});
  }, []);

  const handleLazyLoad = React.useCallback(() => {
    if (!channelId || !dataRef.current?.data) return;

    const {data, metadata} = dataRef.current || {data: [], metadata: {}};

    const sortArray = metadata.sort ? metadata.sort.split('&') : undefined;

    if (data.length === metadata.limit) {
      setLoadingFirstPage(false);

      setParams({
        ...defaultParams,
        ...searchParamsRef.current,
        offset: queueItems.length || defaultParams.limit,
        sort: sortArray as `${string}:${string}`[],
      });
    }
  }, [channelId, queueItems.length]);

  const handleSorting = React.useCallback(
    (sortField: string) => {
      if (!channelId || queueItems.length < 2) return;

      setQueueItems([]);
      setLoadingFirstPage(true);

      const {sort} = dataRef.current?.metadata || {sort: 'order:asc'};

      const [prevSortField, prevSortDirection] = getSplittedSort(getSortString(sort));
      const sortDirection = prevSortField === sortField && prevSortDirection === 'asc' ? 'dsc' : 'asc';

      setParams({
        ...defaultParams,
        ...searchParamsRef.current,
        sort: [`${sortField}:${sortDirection}`],
      });
    },
    [channelId, queueItems.length],
  );

  const addItems = React.useCallback(
    (index: number, items: IChannelCatalogItemWithState[]) => {
      if (isSearchActive) {
        setMovedToQueueDuringFilter(prevItems => [...prevItems, ...items]);
      }
      setQueueItems(addToMemoryQueue(index, items));
      setMovedToQueue(prevItems => [...prevItems, ...items.filter(item => !pristineQueue.some(i => i.id === item.id))]);
    },
    [addToMemoryQueue, isSearchActive, pristineQueue],
  );

  const removeItems = React.useCallback(
    (items: IChannelCatalogItemWithState[]) => {
      setQueueItems(removeItemsById(items));
      removeItemsById(items);
    },
    [removeItemsById],
  );

  const moveAlong = React.useCallback(
    (fromIndex: number[], toIndex: number): void => {
      setQueueItems(moveAlongQueue(fromIndex, toIndex));
    },
    [moveAlongQueue],
  );

  const handleSearching = (formModel: Partial<IChannelQueueQuery>) => {
    setParams({
      ...defaultParams,
      ...searchParamsRef.current,
      ...formModel,
    });
  };

  return {
    queueItems,
    setQueueItems,
    duration,
    sort: getSortString(data?.metadata.sort),
    totalItems,
    isError,
    isLazyLoading: isLoading && !loadingFirstPage,
    isLoading: isLoading && loadingFirstPage,
    handleLazyLoad,
    movedToQueue,
    handleSorting,
    refetchDuration,
    shuffle,
    addItems,
    moveAlong,
    fetch,
    removeItems,
    refetch,
    movedToQueueDuringFilter,
    handleSearching,
  };
};
