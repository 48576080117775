import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IFeaturedGroup} from 'models/featuredGroup';

import {IListPayload} from 'models/generic';

function getParamsFromModel<T extends Partial<Record<keyof T, unknown>>>(searchModel: T) {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return [...acc, {[key]: encodeURIComponent(value)}];
    }
    return acc;
  }, [] as {[key: string]: string | number}[]);
}

export interface IFeaturedGroupQuery {
  offset: number;
  limit: number;
  sort: string;
  groupName?: string;
  channelId?: string;
  activeRegion?: string[] | string;
  published?: boolean;
  startDate?: string;
  startEndDate?: string;
}

export const featuredGroupsApi = createApi({
  reducerPath: 'featuredGroupsApi',
  refetchOnMountOrArgChange: false,
  tagTypes: ['FeaturedGroups', 'FeaturedGroupsSearch', 'FeaturedGroup'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    search: builder.query<IListPayload<IFeaturedGroup>, IFeaturedGroupQuery>({
      query: params => {
        const paramsString = getParamsFromModel<IFeaturedGroupQuery>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');

        const searchParams = new URLSearchParams(paramsString);

        return {
          url: `featured-groups?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      providesTags: ['FeaturedGroupsSearch'],
    }),
    findById: builder.query<IFeaturedGroup, string>({
      query: (id: string) => ({
        url: `featured-groups/${id}`,
        method: 'GET',
      }),
      providesTags: ['FeaturedGroup'],
    }),
    insert: builder.mutation<IFeaturedGroup, Partial<IFeaturedGroup | any>>({
      query: featuredGroup => {
        const bodyParams = featuredGroup;

        if (featuredGroup.contentItems?.length) {
          bodyParams.contentItems = featuredGroup.contentItems?.map(ci => ({
            ...ci,
            content: typeof ci.content === 'string' ? ci.content : ci.content.id,
          }));
        }

        return {
          url: `featured-groups`,
          method: 'POST',
          body: bodyParams,
        };
      },
      invalidatesTags: ['FeaturedGroups', 'FeaturedGroupsSearch'],
    }),
    update: builder.mutation<IFeaturedGroup, {id: string; featuredGroup: Partial<IFeaturedGroup>; fields: string[]}>({
      query: ({id, featuredGroup, fields}) => ({
        url: `featured-groups/${id}`,
        method: 'PUT',
        body: {
          id,
          featuredGroupParams: {
            ...featuredGroup,
            contentItems: featuredGroup.contentItems?.map(ci => ({...ci, content: ci.content.id})),
          },
          updateFields: fields,
        },
      }),
      invalidatesTags: ['FeaturedGroup', 'FeaturedGroupsSearch', 'FeaturedGroups'],
    }),
    delete: builder.mutation<IFeaturedGroup, {ids: string | string[]}>({
      query: params => {
        const paramsString = getParamsFromModel<{ids: string | string[]}>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');

        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `featured-groups?${searchParams.toString()}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['FeaturedGroups', 'FeaturedGroupsSearch'],
    }),
    publish: builder.mutation<IFeaturedGroup, {id: string; acceptWarnings?: boolean}>({
      query: ({id, acceptWarnings}) => {
        const bodyParams = {};
        if (acceptWarnings) {
          bodyParams['acceptWarnings'] = true;
        }
        return {
          url: `featured-groups/${id}/publish`,
          method: 'PUT',
          body: bodyParams,
        };
      },
      invalidatesTags: ['FeaturedGroup', 'FeaturedGroupsSearch', 'FeaturedGroups'],
    }),
    unpublish: builder.mutation<IFeaturedGroup, {id: string; activeRegion: string; acceptWarnings?: boolean}>({
      query: ({id, activeRegion, acceptWarnings}) => {
        const bodyParams = {id, activeRegion};
        if (acceptWarnings) {
          bodyParams['acceptWarnings'] = true;
        }
        return {
          url: `/featured-groups-unpublishing`,
          method: 'PUT',
          body: bodyParams,
        };
      },
      invalidatesTags: ['FeaturedGroup', 'FeaturedGroupsSearch', 'FeaturedGroups'],
    }),
  }),
});

export const {
  useSearchQuery,
  useLazySearchQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  usePublishMutation,
  useUnpublishMutation,
} = featuredGroupsApi;
