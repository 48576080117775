import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IContent} from 'models/content';
import {IListQuery, IListPayload} from 'models/generic';

interface IListContentQuery extends IListQuery {
  type: 'series' | 'movie' | 'channel' | 'vodcategory';
  campaignID: string;
  series?: string;
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  tagTypes: ['Content'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IListPayload<IContent>, IListContentQuery>({
      query: ({offset = 0, limit = 10, sort, type = 'series', search, campaignID, series = null}) => {
        let url = `content?offset=${offset}&limit=${limit}&type=${type}&campaignID=${campaignID}`;

        if (search) {
          url = `${url}&search=${encodeURIComponent(search)}`;
        }

        if (sort) {
          url = `${url}&sort=${sort}`;
        }

        if (series) {
          url = `${url}&series=${series}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Content'],
    }),
    findById: builder.query<IContent, string>({
      query: (id: string) => ({
        url: `content/${id}`,
        method: 'GET',
      }),
      providesTags: ['Content'],
    }),
  }),
});

export const {useFindQuery, useFindByIdQuery} = contentApi;
