import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IAppName} from 'models/appNames';
import {IListPayload, IListQuery} from 'models/generic';

export const appnamesApi = createApi({
  reducerPath: 'appnamesApi',
  tagTypes: ['AppName'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IListPayload<IAppName>, IListQuery>({
      query: ({offset = 0, limit = 10, sort = 'name:asc'}) => ({
        url: `app-names?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['AppName'],
    }),
    findById: builder.query<IAppName, string>({
      query: (id: string) => ({
        url: `app-names/${id}`,
        method: 'GET',
      }),
      providesTags: ['AppName'],
    }),
    insert: builder.mutation<IAppName, Partial<IAppName>>({
      query: appName => ({
        url: `app-names`,
        method: 'POST',
        body: appName,
      }),
      invalidatesTags: ['AppName'],
    }),
    update: builder.mutation<IAppName, {id: string; appName: Partial<IAppName>}>({
      query: ({id, appName}) => ({
        url: `app-names/${id}`,
        method: 'PUT',
        body: appName,
      }),
      invalidatesTags: ['AppName'],
    }),
    delete: builder.mutation<IAppName, string>({
      query: (id: string) => ({
        url: `app-names/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AppName'],
    }),
  }),
});

export const {
  useFindQuery,
  useLazyFindByIdQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} = appnamesApi;
