import * as React from 'react';
import {Status, Table, TdLink} from '@pluto-tv/assemble';
import hubRoutes from 'routes/programming.routes';
import {IHubConfig} from 'models/hubConfigs';
import {useAssociatedHubs} from 'views/programming/hubManager/carousels/edit/details/hooks/useAssociatedHubs';

interface IAssociatedHubsTableProps {
  carouselId: string;
}

type IAssociatedHubs = Pick<IHubConfig, 'published' | 'name' | 'region' | 'id'>;

const AssociatedHubsTable = ({carouselId}: IAssociatedHubsTableProps): React.ReactElement | null => {
  const {data: rows, isLoading, isError} = useAssociatedHubs(carouselId);

  const columns = React.useMemo(
    () => [
      {
        label: 'Name',
        transform: (row: IAssociatedHubs) => (
          <TdLink
            row={row}
            title={row.name}
            url={hubRoutes.paths.hubEditPreviewPage.replace(':id', row.id)}
            target='_blank'
          />
        ),
      },
      {
        label: 'Active Region',
        field: 'region' as const,
      },
      {
        label: 'Published',
        transform: (row: IAssociatedHubs) => (
          <Status state={row.published ? 'success' : 'neutral'} label={row.published ? 'Published' : 'Unpublished'} />
        ),
      },
    ],
    [],
  );

  if (!carouselId) return null;

  return (
    <Table<IAssociatedHubs>
      cols={columns}
      rows={rows}
      loading={isLoading}
      maxHeight='19rem'
      flushTop={true}
      id='associatedHubsTable'
      emptyMsg={
        isError
          ? 'There was an error retrieving associated hubs. Please try again later.'
          : !rows?.length && !isLoading
          ? 'No hub is using this carousel configuration.'
          : undefined
      }
    />
  );
};

export default React.memo(AssociatedHubsTable);
