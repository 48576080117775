import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IGenre} from 'models/genres';

export const genresApi = createApi({
  reducerPath: 'genresApi',
  tagTypes: ['Genres'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IGenre[], void>({
      query: () => ({
        url: `genres`,
        method: 'GET',
      }),
      providesTags: ['Genres'],
    }),
  }),
});

export const {useFindQuery} = genresApi;
