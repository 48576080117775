import {IValidatorField} from '@pluto-tv/assemble';

import {IHubCarousel, IHubConfig, IHubConfigSearch} from 'models/hubConfigs';

export const hubConfigSearchValidator: IValidatorField<IHubConfigSearch>[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'region',
    label: 'Active Region',
  },
];

export const hubConfigSimpleDetails: IValidatorField<IHubConfig>[] = [
  {
    name: 'displayName',
    label: 'Hub Display Name',
    required: true,
    validators: [
      (displayName: string): string | undefined => {
        if (!displayName || !displayName.trim().length) {
          return 'Display Name is required';
        } else if (displayName && displayName.trim().length > 100) {
          return 'Display Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'deviceType',
    label: 'Include',
    required: true,
    validators: [
      (deviceType: [string]): string | undefined => {
        if (!deviceType || !deviceType?.length) {
          return 'Device Type is required';
        }
      },
    ],
  },
  {
    name: 'kidsOnly',
    label: 'Kids Mode',
  },
  {
    name: 'published',
  },
  {
    name: 'name',
    label: 'Hub Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Hub Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Hub Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'expiresInMilliseconds',
    label: 'Refresh Rate',
    validators: [
      (expiresInMilliseconds: number): string | undefined => {
        if (Number.isInteger(expiresInMilliseconds) && expiresInMilliseconds < 0) {
          return 'Refresh Rate must not be negative';
        }
        if (expiresInMilliseconds && !Number.isInteger(expiresInMilliseconds)) {
          return 'Refresh Rate must be a number';
        }
      },
    ],
  },
];

export const hubConfigCreateValidator: IValidatorField<IHubConfig>[] = [
  ...hubConfigSimpleDetails,
  {
    name: 'region',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
];

export const hubConfigSettingsValidator: IValidatorField<IHubConfig>[] = [
  ...hubConfigSimpleDetails,
  {
    name: 'carousels',
  },
];

export const hubCarouselConfigsValidator: IValidatorField<IHubCarousel>[] = [
  {
    name: 'displayName',
    label: 'Display Name',
    required: true,
    validators: [
      (displayName: string): string | undefined => {
        if (!displayName || !displayName.trim().length) {
          return 'Display Name is required';
        } else if (displayName && displayName.trim().length > 100) {
          return 'Display Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'tileSize',
    label: 'Tile Size',
  },
  {
    name: 'name',
    label: 'Name',
  },
];
