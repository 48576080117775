import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useCarouselLazyLoadProvider} from 'components/carouselList/providers/CarouselLazyLoadProvider';
import {Box, Button, Cluster, Click, Cover, Expand, Heading, Icon, Sidebar, Template, Toast} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import hubRoutes from 'routes/programming.routes';
import carouselRoutes from 'routes/programming.routes';
import {ICarouselConfigSearch} from 'models/carouselConfigs';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import CarouselListTable from 'components/carouselList/components/CarouselListTable';
import {ICarouselConfigListProps} from 'components/carouselList';
import CreateCarouselForm from 'components/carouselList/components/CreateCarouselForm';
import CarouselSearch from 'components/carouselList/components/CarouselSearch';
import {carouselConfigTitleId} from 'components/carouselList/components/CarouselSearch';
import {useDeleteMutation} from 'features/carouselConfigs/carouselConfigsApi';
import {useCarouselTableList} from 'components/carouselList/hooks/useCarouselTableList';

const INIT_PARAMS = {
  sortBy: 'createdAt',
  sortDirection: 'desc',
};

const CarouselConfigList = React.memo(
  ({
    actionsCol = true,
    addNewCarouselConfig = true,
    inModal = false,
    //showFavoriteSearch = true,
    isSearchExpanded = false,
  }: ICarouselConfigListProps) => {
    const history = useHistory();
    const {ableTo} = useAppPermissions();

    const canCreate = ableTo('CAROUSEL_CREATE');

    const {isError, reset, totalCount: firstTotalCount} = useCarouselLazyLoadProvider();
    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [isSearchActive, setIsSearchActive] = React.useState(false);
    const [searchParams, setSearchParams] = React.useState<ICarouselConfigSearch>();

    const {
      totalCount: searchTotalCount,
      search: carouselConfigSearch,
      reset: searchReset,
      items: carouselConfigItems,
      lazyLoad: carouselLazyLoad,
      isLazyLoading: carouselConfigIsLazyLoading,
      isError: carouselConfigIsError,
      isLoading: carouselConfigIsLoading,
      searchParams: carouselConfigSearchParams,
    } = useCarouselTableList(isSearchActive);
    const [deleteCarousel] = useDeleteMutation();

    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

    React.useEffect(() => {
      if (searchExpanded) {
        setTimeout(() => {
          const carouselTitleInput = document.getElementById(carouselConfigTitleId);
          carouselTitleInput?.focus({
            preventScroll: true,
          });
        });
      }
    }, [searchExpanded]);

    React.useEffect(() => {
      if (isError) {
        Toast.error('Error', 'There was an error retrieving carousel configurations. Please try again later.');
      }
    }, [isError]);

    React.useEffect(() => {
      if (!isSearchActive && !searchParams) {
        carouselConfigSearch(INIT_PARAMS);
      } else if (isSearchActive && searchParams) {
        carouselConfigSearch(searchParams);
      }
    }, [carouselConfigSearch, isSearchActive, searchParams]);

    const handleEdit = (id: string) => {
      history.push(carouselRoutes.paths.carouselEditDetailsPage.replace(':id', id));
    };

    const handleDelete = async (id: string) => {
      try {
        await deleteCarousel(id).unwrap();

        Toast.success('Success', 'The carousel was deleted successfully.');
        isSearchActive ? searchReset() : reset(true);
      } catch (error: any) {
        if ((error?.data?.message as string).includes('the carousel is referenced by hubs')) {
          Toast.error(
            'This carousel is referenced by one or more hubs and cannot be deleted.',
            'Please remove from all hubs in order to delete.',
          );
          return;
        }

        Toast.error('Error deleting carousel. Please try again.');
      }
    };

    const handleCreate = () => {
      isSearchActive && searchReset();
    };

    const openCreate = () => setCreateOpen(true);

    const handleSearch = (search: ICarouselConfigSearch) => {
      setIsSearchActive(true);
      setSearchParams(search);
    };

    const handleSort = (search: ICarouselConfigSearch) => {
      let params = {...search};

      if (carouselConfigSearchParams) {
        params = {...carouselConfigSearchParams, ...search};
      }

      carouselConfigSearch(params);
    };

    const handleClear = () => {
      setIsSearchActive(false);
      searchReset(true);
      setSearchParams(undefined);
    };

    return (
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box
              background='pewter'
              paddingY={inModal ? 'none' : 'medium'}
              paddingRight='medium'
              paddingLeft={inModal ? 'none' : 'medium'}
              fullHeight={true}
            >
              <CarouselSearch setIsExpanded={setSearchExpanded} onClear={handleClear} onSearch={handleSearch} />
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Box width='17.8125rem'>
                <Cluster align='end' space='small'>
                  <Heading level='h1'>Carousels</Heading>

                  <Icon
                    id='expandFilter'
                    icon='tune'
                    space='xxxsmall'
                    verticalAlign='bottom'
                    lineHeight='0px'
                    onClick={() => setSearchExpanded(!searchExpanded)}
                  >
                    {isSearchActive ? searchTotalCount : firstTotalCount} Items
                  </Icon>
                </Cluster>
              </Box>
              <div style={{position: 'relative', marginTop: '0.625rem', marginBottom: '-1.625rem', zIndex: 9}}>
                <Cluster space='xxlarge'>
                  <Click
                    id='hubConfig'
                    paddingBottom='xxlarge'
                    borderBottom={false}
                    borderSize='0.125rem'
                    borderStyle='solid'
                    borderColor='primary'
                    color='dust'
                    hoverColor='primary'
                    size='large'
                    onClick={() => history.push(hubRoutes.paths.hubManagerPage)}
                  >
                    Hub Configurations
                  </Click>
                  <Click
                    id='carouselConfig'
                    paddingBottom='xlarge'
                    borderBottom={true}
                    borderSize='0.125rem'
                    borderStyle='solid'
                    borderColor='primary'
                    color='white'
                    hoverColor='white'
                    size='large'
                    onClick={() => history.push(hubRoutes.paths.carouselListPage)}
                  >
                    Carousel Configurations
                  </Click>
                </Cluster>
              </div>
              <Box width='11.5625rem'>
                <Cluster space='small' align='center' justify='end'>
                  {addNewCarouselConfig ? (
                    <>
                      <Button
                        id='addCarouselConfigButton'
                        type='primary'
                        onClick={() => openCreate()}
                        permission={canCreate ? '' : 'hidden'}
                      >
                        + New Carousel Config
                      </Button>
                      {createOpen && <CreateCarouselForm onCreate={handleCreate} setCreateOpen={setCreateOpen} />}
                    </>
                  ) : (
                    <Box height='1.7rem'></Box>
                  )}
                </Cluster>
              </Box>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='2px'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : 'xsmall'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <CarouselListTable
                onDelete={handleDelete}
                onEdit={handleEdit}
                actionsCol={actionsCol}
                isSearchActive={isSearchActive}
                carouselConfigItems={carouselConfigItems}
                lazyLoad={carouselLazyLoad}
                isLazyLoading={carouselConfigIsLazyLoading}
                isError={carouselConfigIsError}
                isLoading={carouselConfigIsLoading}
                search={handleSort}
              />
            </Box>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

CarouselConfigList.displayName = 'CarouselList';
export default CarouselConfigList;
