import {staggingRoutesMapping} from 'helpers/staggingRoutesMapping';
import {ICarouselConfig} from 'models/carouselConfigs';

export const unknownService = 'Unknown Service';
export const unknownSource = 'Unknown Source';

const sourceMappings = {
  mlcarousels: 'ML Carousels',
  vod: 'Vod Collections',
  'feature-store': 'Feature Store',
  watchlist: 'Watchlist',
  recommender: 'Recommended',
  campaigns: 'Campaigns',
  mediacatalog: 'Media Catalog',
} as const;

export const serviceMappings = {
  mlcarousels: {
    ['automated-carousels']: 'Automated Carousels',
  },
  vod: {
    categories: 'Collections',
  },
  'feature-store': {
    channels: 'Channels',
    movies: 'Movies',
    tvshows: 'TV Shows',
  },
  watchlist: {
    'favorite-channels': 'Favorite Channels',
    'recently-watched': 'Recently Watched',
    'resume-points': 'Resume Points',
    watchlist: 'Watchlist Items',
  },
  recommender: {
    'personalized-channels': 'Personalized Channels',
    'personalized-movies': 'Personalized Movies',
    'personalized-tvshows': 'Personalized TV Shows',
    'recommended-series': 'Recommended Series',
    'recommended-channels': 'Recommended Channels',
    'similar-channels': 'Similar Channels',
    'similar-movies': 'Similar Movies',
    'similar-tvshows': 'Similar TV Shows',
  },
  campaigns: {
    'primetime-billboard': 'Prime Time Content',
  },
  mediacatalog: {
    'main-categories': 'Main Categories',
    'vod-main-categories': 'VOD Main Categories',
  },
} as const;

const apiUrlSourceMappings = {
  recommender: '/v1/recommend',
  personalized: '/v1/personalized',
  similar: '/v1/similar',
  campaigns: '/v2/campaigns',
  vod: `/v4/vod/categories/ASSOCIATED-ID/items`,
  mediacatalog: '/v1/main-categories',
  ['feature-store']: '/not-used',
  mlcarousels: '/v1/carousel/ASSOCIATED-ID',
} as const;

const apiUrlServiceMappings = {
  ['personalized-channels']: 'segment=channels',
  ['personalized-movies']: 'segment=movies',
  ['personalized-tvshows']: 'segment=tvshows',
  ['recommended-series']: 'segment=on-demand',
  ['recommended-channels']: 'segment=channels',
  ['similar-channels']: 'segment=channels&id=ASSOCIATED-ID',
  ['similar-movies']: 'segment=movies&id=ASSOCIATED-ID',
  ['similar-tvshows']: 'segment=tvshows&id=ASSOCIATED-ID',
  ['favorite-channels']: '/v1/favorite/channels?limit=100',
  ['recently-watched']: '/v1/recently-watched',
  ['resume-points']: '/v1/resume-points?dedupe=true',
  ['watchlist']: '/v1/watchlist',
};

export const routeMappingWithCMSClassic = (): string => {
  const response = Object.values(staggingRoutesMapping).find(rou => rou.TNG.includes(location.origin))?.Classic;

  // If you're running in localhost or any other test env it'll return PUBSTE1
  return response ?? staggingRoutesMapping.PUBSTE1.Classic;
};

export const policyMappings = {
  append: 'Append',
  replace: 'Replace',
  merge: 'Merge',
} as const;

export const convertToApiUrl = (
  source: string,
  service?: string,
  associatedCarousel?: {name: string; id: string},
): string => {
  if (source === 'recommender' && service) {
    if (service.includes('similar') && associatedCarousel)
      return (`${apiUrlSourceMappings['similar']}?${apiUrlServiceMappings[service]}` as string).replace(
        'ASSOCIATED-ID',
        associatedCarousel.id,
      );

    if (service.includes('personalized'))
      return `${apiUrlSourceMappings['personalized']}?${apiUrlServiceMappings[service]}`;

    return `${apiUrlSourceMappings[source]}?${apiUrlServiceMappings[service]}`;
  }

  if (source === 'watchlist' && service) return apiUrlServiceMappings[service];

  const api = apiUrlSourceMappings[source];
  if ((source === 'vod' || source === 'mlcarousels') && associatedCarousel)
    return (api as string).replace('ASSOCIATED-ID', associatedCarousel.id);

  return api;
};

export type ApiService = `${keyof typeof sourceMappings}#${keyof typeof serviceMappings}`;
export type SourceMappingValue = typeof sourceMappings[keyof typeof sourceMappings];

export const convertApiServiceToSource = (apiService: ApiService, separator = '#'): SourceMappingValue => {
  const [source] = apiService.split(separator);
  return sourceMappings[source] || (unknownSource as SourceMappingValue);
};
export const convertApiServiceToService = (apiService: ApiService, separator = '#'): string => {
  const [source, service] = apiService.split(separator);
  if (!source || !service) return unknownService;
  return serviceMappings?.[source]?.[service] || unknownService;
};

const convertApis = <T>(
  apis: ICarouselConfig['apis'] | undefined,
  converter: (apiService: ApiService, separator?: string) => T,
) => {
  return (apis || []).reduce((acc, api) => {
    const result = converter(api.apiService as ApiService);
    acc.push(result);
    return acc;
  }, [] as T[]);
};

export const convertApisToSources = (apis?: ICarouselConfig['apis']): SourceMappingValue[] => {
  if (!apis) return [];
  return convertApis<SourceMappingValue>(apis, convertApiServiceToSource);
};

export const convertApisToServices = (apis?: ICarouselConfig['apis']): string[] => {
  if (!apis) return [];
  return convertApis<string>(apis, convertApiServiceToService);
};
