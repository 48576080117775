import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IMainCategory} from 'models/mainCategories';

export const mainCategoriesApi = createApi({
  reducerPath: 'mainCategoriesApi',
  tagTypes: ['MainCategories'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IMainCategory[], void>({
      query: () => ({
        url: `main-categories`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3600,
      providesTags: ['MainCategories'],
    }),
  }),
});

export const {useFindQuery} = mainCategoriesApi;
