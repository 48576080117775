export const hubManagerPermissions = {
  HUB_VIEW: {
    enabled: [
      'hub_carousel_configs_view',
      'hub_carousel_configs_edit',
      'hub_carousel_configs_create',
      'hub_carousel_configs_delete',
    ],
  },
  HUB_DELETE: {
    enabled: ['hub_carousel_configs_delete'],
  },
  HUB_CREATE: {
    enabled: ['hub_carousel_configs_create'],
  },
  HUB_EDIT: {
    enabled: ['hub_carousel_configs_edit'],
    disabled: ['hub_carousel_configs_view', 'hub_carousel_configs_create', 'hub_carousel_configs_delete'],
  },
  CAROUSEL_VIEW: {
    enabled: [
      'hub_carousel_configs_view',
      'hub_carousel_configs_edit',
      'hub_carousel_configs_create',
      'hub_carousel_configs_delete',
    ],
  },
  CAROUSEL_DELETE: {
    enabled: ['hub_carousel_configs_delete'],
  },
  CAROUSEL_CREATE: {
    enabled: ['hub_carousel_configs_create'],
  },
  CAROUSEL_EDIT: {
    enabled: ['hub_carousel_configs_edit'],
    disabled: ['hub_carousel_configs_view', 'hub_carousel_configs_create', 'hub_carousel_configs_delete'],
  },
};
