import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IDevice} from 'models/devices';
import {IListPayload, IListQuery} from 'models/generic';

export const devicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: baseQueryWithAuth,
  endpoints(builder) {
    return {
      find: builder.query<IListPayload<IDevice>, IListQuery>({
        query: ({offset = 0, limit = 10, sort = 'name:asc'}) => ({
          url: `devicetypes?offset=${offset}&limit=${limit}&sort=${sort}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useFindQuery} = devicesApi;
