import {Popover, Template, Icon, Box, Stack, TColorSpectrum, TColors} from '@pluto-tv/assemble';
import * as React from 'react';

export const RowState = ({row}: {row: any}): JSX.Element => {
  return (
    <>
      {(row.state === 'warning' || row.state === 'error') && (
        <Popover trigger='mouseenter'>
          <Template label='trigger'>
            <Icon icon='warning' verticalAlign='text-bottom' color={row.state} />
          </Template>
          <Template label='popover'>
            <Box paddingX='small' paddingY='xxsmall' background='charcoal' minWidth='18.75rem'>
              <Stack space='xxxsmall'>
                {row.stateMsgList?.length &&
                  row.stateMsgList.map((stateMsg: {color: TColors | TColorSpectrum; label: string}, index: number) => (
                    <Icon icon='warning' color={stateMsg.color} space='small' key={index}>
                      {stateMsg.label}
                    </Icon>
                  ))}
              </Stack>
            </Box>
          </Template>
        </Popover>
      )}
    </>
  );
};
