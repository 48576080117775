import * as React from 'react';
import {
  Stack,
  Divider,
  Accordion,
  Template,
  Paragraph,
  Box,
  Icon,
  Cluster,
  Click,
  Popover,
  FormItem,
  TextInput,
  Button,
  Sidebar,
  Heading,
  useValidateForm,
  Spinner,
  Toast,
} from '@pluto-tv/assemble';
import CrudError from 'components/crudError';
import DeleteConfirmation from 'components/deleteConfirmation';
import {usePreferences} from 'helpers/usePreferences';
import {cloneDeep, filter, find, reject} from 'lodash-es';
import {
  IUserClipSearch,
  IUserEpisodeSearch,
  IUserSeriesSearch,
  IUserChannelsSearch,
  IUserLicensedTitleSearch,
  IUserRunLimitTrackerSearch,
  IUserVodCollectionSearch,
  IUserHubConfigSearch,
  IUserCarouselConfigSearch,
  IUserCampaignSearch,
} from 'models/users';

export type IGenericSearchModel =
  | IUserClipSearch
  | IUserEpisodeSearch
  | IUserSeriesSearch
  | IUserChannelsSearch
  | IUserLicensedTitleSearch
  | IUserRunLimitTrackerSearch
  | IUserVodCollectionSearch
  | IUserHubConfigSearch
  | IUserCarouselConfigSearch
  | IUserCampaignSearch;
export interface IFavoriteSearchProps<T> {
  searchModel?: T;
  getValidation?: () => Promise<Partial<T>>;
  onSearchSelected: (search: T) => void;
  searchSelected?: IGenericSearchModel;
  onClearSelection: () => void;
}

const ChannelFavoriteSearch = React.memo(
  ({
    searchModel,
    getValidation,
    onSearchSelected,
    searchSelected,
    onClearSelection,
  }: IFavoriteSearchProps<IUserChannelsSearch>) => {
    const {isFetching, isError, channelsSearches, updateSavedSearches} = usePreferences<IUserChannelsSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: any[]) => {
      updateSavedSearches('channels', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search: any) => {
      if (!search) {
        onClearSelection();
        return;
      }
      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={channelsSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
ChannelFavoriteSearch.displayName = 'ChannelFavoriteSearch';

const VodCollectionFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserVodCollectionSearch>) => {
    const {isFetching, isError, vodCollectionsSearches, updateSavedSearches} =
      usePreferences<IUserVodCollectionSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: any[]) => {
      updateSavedSearches('vodCollections', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search: any) => {
      if (!search) {
        onClearSelection();
        return;
      }
      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        isFetching={isFetching}
        getValidation={getValidation}
        isError={isError}
        searches={vodCollectionsSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
VodCollectionFavoriteSearch.displayName = 'VodCollectionFavoriteSearch';

const HubConfigFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserHubConfigSearch>) => {
    const {isFetching, isError, hubConfigsSearches, updateSavedSearches} = usePreferences<IUserHubConfigSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: any[]) => {
      updateSavedSearches('hubConfigs', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search: any) => {
      if (!search) {
        onClearSelection();
        return;
      }
      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={hubConfigsSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
HubConfigFavoriteSearch.displayName = 'HubConfigFavoriteSearch';

const CarouselConfigFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserCarouselConfigSearch>) => {
    const {isFetching, isError, carouselConfigsSearches, updateSavedSearches} =
      usePreferences<IUserCarouselConfigSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: any[]) => {
      updateSavedSearches('carouselConfigs', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search: any) => {
      if (!search) {
        onClearSelection();
        return;
      }
      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={carouselConfigsSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
CarouselConfigFavoriteSearch.displayName = 'CarouselConfigFavoriteSearch';

const ClipFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserClipSearch>) => {
    const {isFetching, isError, clipSearches, updateSavedSearches} = usePreferences<IUserClipSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserClipSearch[]) => {
      updateSavedSearches('clips', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search: IUserClipSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={clipSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
ClipFavoriteSearch.displayName = 'ClipFavoriteSearch';

const EpisodeFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserEpisodeSearch>) => {
    const {isFetching, isError, episodeSearches, updateSavedSearches} = usePreferences<IUserEpisodeSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserEpisodeSearch[]) => {
      updateSavedSearches('episodes', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search?: IUserEpisodeSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={episodeSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
EpisodeFavoriteSearch.displayName = 'EpisodeFavoriteSearch';

const SeriesFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserSeriesSearch>) => {
    const {isFetching, isError, seriesSearches, updateSavedSearches} = usePreferences<IUserSeriesSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserSeriesSearch[]) => {
      updateSavedSearches('series', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search?: IUserSeriesSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={seriesSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
SeriesFavoriteSearch.displayName = 'SeriesFavoriteSearch';

const LicensedTitleFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserLicensedTitleSearch>) => {
    const {isFetching, isError, licensedTitleSearches, updateSavedSearches} =
      usePreferences<IUserLicensedTitleSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserLicensedTitleSearch[]) => {
      updateSavedSearches('licensedTitle', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search?: IUserLicensedTitleSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={licensedTitleSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
LicensedTitleFavoriteSearch.displayName = 'LicensedTitleFavoriteSearch';

const RunLimitTrackerFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserRunLimitTrackerSearch>) => {
    const {isFetching, isError, runLimitTrackerSearches, updateSavedSearches} =
      usePreferences<IUserRunLimitTrackerSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserRunLimitTrackerSearch[]) => {
      updateSavedSearches('runLimitTracker', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search?: IUserRunLimitTrackerSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={runLimitTrackerSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
RunLimitTrackerFavoriteSearch.displayName = 'RunLimitTrackerFavoriteSearch';

const CampaignFavoriteSearch = React.memo(
  ({
    searchModel,
    onSearchSelected,
    searchSelected,
    onClearSelection,
    getValidation,
  }: IFavoriteSearchProps<IUserCampaignSearch>) => {
    const {isFetching, isError, campaignSearches, updateSavedSearches} = usePreferences<IUserCampaignSearch>();

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const updateSearchesHandler = async (searches: IUserCampaignSearch[]) => {
      updateSavedSearches('campaigns', searches);
      setDrawerOpen(true);
    };

    const searchSelectedHandler = (search?: IUserCampaignSearch) => {
      if (!search) {
        onClearSelection();
        return;
      }

      onSearchSelected(search);
    };

    return (
      <FavoriteSearch
        searchModel={searchModel}
        getValidation={getValidation}
        isFetching={isFetching}
        isError={isError}
        searches={campaignSearches}
        onUpdateSearches={updateSearchesHandler}
        onSearchSelected={searchSelectedHandler}
        activeSearch={searchSelected}
        drawerOpen={drawerOpen}
      />
    );
  },
);
CampaignFavoriteSearch.displayName = 'CampaignFavoriteSearch';

export {
  EpisodeFavoriteSearch,
  ClipFavoriteSearch,
  SeriesFavoriteSearch,
  ChannelFavoriteSearch,
  LicensedTitleFavoriteSearch,
  RunLimitTrackerFavoriteSearch,
  VodCollectionFavoriteSearch,
  HubConfigFavoriteSearch,
  CarouselConfigFavoriteSearch,
  CampaignFavoriteSearch,
};

interface IGenericFavoriteSearchParams {
  searchModel?: IGenericSearchModel;
  getValidation?: () => Promise<Partial<IGenericSearchModel>>;
  isFetching: boolean;
  isError: boolean;
  searches: any[];
  onUpdateSearches: (searches: any[]) => Promise<void>;
  onSearchSelected: (search: any) => void;
  activeSearch?: IGenericSearchModel;
  drawerOpen?: boolean;
}
const FavoriteSearch = React.memo(
  ({
    searchModel,
    getValidation,
    isFetching,
    isError,
    searches,
    onUpdateSearches,
    onSearchSelected,
    activeSearch,
    drawerOpen = false,
  }: IGenericFavoriteSearchParams) => {
    const [isFavSearchCreateOpen, setIsFavSearchCreateOpen] = React.useState(false);
    const [isFavSearchSaveAsOpen, setIsFavSearchSaveAsOpen] = React.useState(false);
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = React.useState(false);
    const [searchDeleteVisible, setSearchDeleteVisible] = React.useState<boolean[]>([]);
    const [searchRenameVisible, setSearchRenameVisible] = React.useState<boolean[]>([]);
    const [editSearch, setEditSearch] = React.useState<number>();

    const {
      model: searchingModel,
      form: searchingForm,
      onChange: searchingOnChange,
      onBlur: searchingOnBlur,
      setCustomErrorMsg: searchingSetCustomErrorMsg,
      setModel: searchingSetModel,
      state: searchingState,
    } = useValidateForm<{search: string}>([{name: 'search'}]);

    const getSearchModel = async (): Promise<IGenericSearchModel | undefined> => {
      if (getValidation) {
        const {model} = await getValidation();

        return {
          ...searchModel!,
          model: model as any,
        };
      }

      return searchModel;
    };

    const createSavedSearch = async () => {
      const search = await getSearchModel();

      const newSearch = {
        ...search,
        name: searchingModel.search?.trim(),
      } as IGenericSearchModel;

      if (find(searches, search => search.name === searchingModel.search?.trim())) {
        searchingSetCustomErrorMsg(
          'search',
          'Saved search with that name already exists! Please choose a different name.',
        );
        return;
      }

      try {
        await onUpdateSearches([...searches, newSearch]);

        onSearchSelected(newSearch);

        Toast.success('Created new saved search');

        setIsFavSearchSaveAsOpen(false);
        setIsFavSearchCreateOpen(false);
      } catch (e) {
        Toast.error('Error creating new saved search. Please try again');
      }
    };

    const renameSavedSearch = async () => {
      if (editSearch === undefined) {
        return;
      }

      if (
        find(
          reject(searches, search => search.name === searches[editSearch].name),
          search => search.name === searchingModel.search?.trim(),
        )
      ) {
        searchingSetCustomErrorMsg(
          'search',
          'Saved search with that name already exists! Please choose a different name.',
        );
        return;
      }

      try {
        const updatedSearches = cloneDeep(searches);
        updatedSearches[editSearch].name = searchingModel.search?.trim();

        if (activeSearch?.name === searches[editSearch].name) {
          onSearchSelected({
            ...(activeSearch as IGenericSearchModel),
            name: searchingModel.search!,
          });
        }

        await onUpdateSearches(updatedSearches);

        Toast.success('Renamed Search Successfully');

        setSearchRenameVisible([]);
        setEditSearch(undefined);
      } catch (e) {
        Toast.error('Error renaming saved search. Please try again');
      }
    };

    if (isError) {
      return <CrudError error='Error loading favorite searches' />;
    }

    if (isFetching) {
      return (
        <Box fullHeight={true}>
          <Spinner center={true} minHeight='9.375rem' size='xlarge' />
        </Box>
      );
    }

    return (
      <Stack space='medium'>
        <Divider color='graphite' />
        <Accordion isOpen={drawerOpen}>
          <Template label='trigger'>
            <Paragraph>Favorite Searches</Paragraph>
          </Template>
          <Template label='body'>
            <Box marginTop='small' marginBottom='xxxxxsmall' marginLeft='medium'>
              <Stack space='medium'>
                <Box maxHeight='9.375rem'>
                  <Stack space='medium'>
                    {searches.length === 0 ? (
                      <Icon icon='warning' color='warning' space='xxsmall'>
                        No Saved Searches
                      </Icon>
                    ) : (
                      searches.map((savedSearch, i) => (
                        <Cluster space='small' align='center' wrap={false} key={savedSearch.name}>
                          <Click
                            size='small'
                            truncate={true}
                            truncateBackgroundHover='pewter'
                            color='primary'
                            hoverColor='primaryLight'
                            underline={activeSearch?.name === savedSearch.name}
                            onClick={() => onSearchSelected(savedSearch)}
                          >
                            {savedSearch.name}
                          </Click>
                          <Popover
                            appendToBody={true}
                            manualTrigger={true}
                            visible={searchRenameVisible[i]}
                            onClickOutside={() => {
                              setEditSearch(undefined);
                              setSearchRenameVisible([]);
                            }}
                          >
                            <Template label='trigger'>
                              <Icon
                                color='neutral'
                                icon='edit'
                                size='small'
                                onClick={() => {
                                  setEditSearch(i);
                                  searchingSetCustomErrorMsg('search', '');
                                  searchingSetModel({
                                    search: savedSearch.name,
                                  });
                                  setSearchRenameVisible([...new Array(i), true]);
                                }}
                              />
                            </Template>
                            <Template label='popover'>
                              <Box padding='small' background='charcoal'>
                                <Stack space='small'>
                                  <FormItem
                                    {...searchingForm.search}
                                    label='Search Name'
                                    state={
                                      searchingForm.search?.helpText && searchingForm.search?.helpText.length > 0
                                        ? 'error'
                                        : undefined
                                    }
                                    onBlur={() => searchingOnBlur('search')}
                                  >
                                    <TextInput
                                      value={searchingModel.search}
                                      onChange={val => searchingOnChange('search', val)}
                                    />
                                  </FormItem>
                                  <Cluster justify='space-between'>
                                    <div></div>
                                    <Cluster>
                                      <Button
                                        ghost={true}
                                        onClick={() => {
                                          setEditSearch(undefined);
                                          setSearchRenameVisible([]);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type='primary'
                                        onClick={renameSavedSearch}
                                        state={
                                          isFetching
                                            ? 'thinking'
                                            : !searchingState.isDirty ||
                                              !searchingState.isValid ||
                                              !searchingModel.search?.trim()
                                            ? 'disabled'
                                            : ''
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Cluster>
                                  </Cluster>
                                </Stack>
                              </Box>
                            </Template>
                          </Popover>
                          <Popover
                            appendToBody={true}
                            manualTrigger={true}
                            visible={searchDeleteVisible[i]}
                            onClickOutside={() => setSearchDeleteVisible([])}
                          >
                            <Template label='trigger'>
                              <Icon
                                color='delete'
                                hoverColor='delete'
                                icon='delete'
                                size='small'
                                onClick={() => setSearchDeleteVisible([...new Array(i), true])}
                              />
                            </Template>
                            <Template label='popover'>
                              <DeleteConfirmation
                                message='Are you sure you want to delete this search?'
                                cancelButtonFunction={() => setSearchDeleteVisible([])}
                                proceedButtonFunction={async () => {
                                  await onUpdateSearches(filter(searches, search => search.name !== savedSearch.name));

                                  if (savedSearch.name === activeSearch?.name) {
                                    onSearchSelected(undefined);
                                  }

                                  setSearchDeleteVisible([]);
                                }}
                              />
                            </Template>
                          </Popover>
                        </Cluster>
                      ))
                    )}
                  </Stack>
                </Box>
                <Cluster justify='space-between'>
                  <div></div>
                  {activeSearch ? (
                    <Cluster space='small'>
                      <Popover
                        appendToBody={true}
                        manualTrigger={true}
                        visible={isFavSearchSaveAsOpen}
                        onClickOutside={() => setIsFavSearchSaveAsOpen(false)}
                      >
                        <Template label='trigger'>
                          <Button
                            size='small'
                            type='primary'
                            onClick={() => {
                              searchingSetCustomErrorMsg('search', '');
                              searchingSetModel({
                                search: '',
                              });
                              setIsFavSearchSaveAsOpen(true);
                            }}
                          >
                            Save As
                          </Button>
                        </Template>
                        <Template label='popover'>
                          <Box padding='small' background='charcoal'>
                            <Stack space='small'>
                              <FormItem
                                {...searchingForm.search}
                                state={
                                  searchingForm.search?.helpText && searchingForm.search?.helpText.length > 0
                                    ? 'error'
                                    : undefined
                                }
                                onBlur={() => searchingOnBlur('search')}
                              >
                                <TextInput
                                  value={searchingModel.search}
                                  onChange={val => searchingOnChange('search', val)}
                                />
                              </FormItem>
                              <Cluster justify='space-between'>
                                <div></div>
                                <Cluster>
                                  <Button ghost={true} onClick={() => setIsFavSearchSaveAsOpen(false)}>
                                    Cancel
                                  </Button>
                                  <Button
                                    type='primary'
                                    onClick={createSavedSearch}
                                    state={
                                      isFetching
                                        ? 'thinking'
                                        : !searchingState.isDirty ||
                                          !searchingState.isValid ||
                                          !searchingModel?.search?.trim()
                                        ? 'disabled'
                                        : ''
                                    }
                                  >
                                    Save As
                                  </Button>
                                </Cluster>
                              </Cluster>
                            </Stack>
                          </Box>
                        </Template>
                      </Popover>
                      <Popover
                        appendToBody={true}
                        manualTrigger={true}
                        visible={isSaveConfirmationOpen}
                        onClickOutside={() => setIsSaveConfirmationOpen(false)}
                      >
                        <Template label='trigger'>
                          <Button size='small' type='primary' onClick={() => setIsSaveConfirmationOpen(true)}>
                            Save
                          </Button>
                        </Template>
                        <Template label='popover'>
                          <Box padding='xxsmall'>
                            <Sidebar gap='large'>
                              <Icon icon='warning' size='xxxlarge' />
                              <Box paddingTop='xsmall'>
                                <Stack space='large'>
                                  <Heading level='h3'>Are you sure?</Heading>
                                  <Paragraph>
                                    You are about to override your saved search titled &apos;{activeSearch.name}&apos;.
                                  </Paragraph>
                                  <Cluster justify='space-between'>
                                    <div></div>
                                    <Cluster space='xxxxsmall'>
                                      <Button ghost={true} onClick={() => setIsSaveConfirmationOpen(false)}>
                                        Cancel
                                      </Button>
                                      <Button
                                        type='primary'
                                        onClick={async () => {
                                          const allSearches = cloneDeep(searches);
                                          const gotSearch = await getSearchModel();

                                          allSearches.forEach(search => {
                                            if (search.name === activeSearch.name) {
                                              search.meta = searchModel?.meta || {};
                                              search.model = gotSearch?.model || {};
                                            }
                                          });

                                          await onUpdateSearches(allSearches);
                                          setIsSaveConfirmationOpen(false);
                                        }}
                                      >
                                        Yes, Proceed
                                      </Button>
                                    </Cluster>
                                  </Cluster>
                                </Stack>
                              </Box>
                            </Sidebar>
                          </Box>
                        </Template>
                      </Popover>
                    </Cluster>
                  ) : (
                    <Popover
                      appendToBody={true}
                      manualTrigger={true}
                      visible={isFavSearchCreateOpen}
                      onClickOutside={() => setIsFavSearchCreateOpen(false)}
                    >
                      <Template label='trigger'>
                        <Button
                          size='small'
                          type='primary'
                          onClick={() => {
                            searchingSetCustomErrorMsg('search', '');
                            searchingSetModel({
                              search: '',
                            });
                            setIsFavSearchCreateOpen(true);
                          }}
                        >
                          + New Favorite Search
                        </Button>
                      </Template>
                      <Template label='popover'>
                        <Box padding='small' background='charcoal'>
                          <Stack space='small'>
                            <FormItem
                              {...searchingForm.search}
                              label='Search Name'
                              state={
                                searchingForm.search?.helpText && searchingForm.search?.helpText.length > 0
                                  ? 'error'
                                  : undefined
                              }
                              onBlur={() => searchingOnBlur('search')}
                            >
                              <TextInput
                                id='search-name-input'
                                value={searchingModel.search}
                                onChange={val => searchingOnChange('search', val)}
                              />
                            </FormItem>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Cluster>
                                <Button ghost={true} onClick={() => setIsFavSearchCreateOpen(false)}>
                                  Cancel
                                </Button>
                                <Button
                                  type='primary'
                                  onClick={createSavedSearch}
                                  state={
                                    isFetching
                                      ? 'thinking'
                                      : !searchingState.isDirty ||
                                        !searchingState.isValid ||
                                        !searchingModel.search?.trim()
                                      ? 'disabled'
                                      : ''
                                  }
                                >
                                  Create
                                </Button>
                              </Cluster>
                            </Cluster>
                          </Stack>
                        </Box>
                      </Template>
                    </Popover>
                  )}
                </Cluster>
              </Stack>
            </Box>
          </Template>
        </Accordion>
      </Stack>
    );
  },
);
