import * as React from 'react';
import {
  Box,
  Center,
  Cover,
  Template,
  Stack,
  Divider,
  Heading,
  Button,
  Cluster,
  Spinner,
  Paragraph,
  findParentByClass,
} from '@pluto-tv/assemble';
import CarouselBlock from 'components/carousel-block';
import programmingRoutes from 'routes/programming.routes';
import {reorderList} from 'helpers/dragAndDrop';
import {ICarouselBlockItem, IHubCarousel} from 'models/hubConfigs';
import {throttle} from 'lodash-es';

interface ICarouselListProps {
  carouselTilesList?: IHubCarousel[];
  onCreate: () => void;
  onApplyOrder: (hubCarousels: IHubCarousel[]) => void;
  isLoading: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

const CarouselList = React.memo(
  ({carouselTilesList, onCreate, isLoading, onApplyOrder, canEdit, canDelete}: ICarouselListProps): JSX.Element => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const draggedItemIndex = React.useRef<number | null>(null);
    const [activeDividerIndex, setActiveDividerIndex] = React.useState<number | null>(null);

    const handleAutoscroll = React.useMemo(
      () =>
        throttle(
          (ev: React.DragEvent<HTMLDivElement>) => {
            ev.preventDefault();

            if (wrapperRef.current && wrapperRef.current.parentElement) {
              const rect = wrapperRef.current.parentElement.getBoundingClientRect();
              if (ev.clientY < rect.top + 50) {
                wrapperRef.current!.parentElement.scrollTo(0, wrapperRef.current!.parentElement.scrollTop - 50);
              }
              if (ev.clientY > rect.bottom - 50) {
                wrapperRef.current!.parentElement.scrollTo(0, wrapperRef.current!.parentElement.scrollTop + 50);
              }
            }
          },
          100,
          {trailing: false},
        ),
      [],
    );

    const removeDragImage = React.useCallback(() => {
      const hasDragImage = document.getElementById('drag-image');

      if (hasDragImage) {
        document.body.removeChild(hasDragImage);
      }
    }, []);

    const createDragImage = React.useCallback(
      (parent: Element | undefined): Element | undefined => {
        removeDragImage();

        if (!parent) {
          return;
        }

        return parent;
      },
      [removeDragImage],
    );

    const handleDrop = () => {
      setActiveDividerIndex(null);

      if (draggedItemIndex.current === null || activeDividerIndex === null || carouselTilesList?.length === 0) return;
      onApplyOrder(reorderList([draggedItemIndex.current], activeDividerIndex, carouselTilesList as IHubCarousel[]));

      // Reset drag states
      draggedItemIndex.current = null;
    };

    const handleDragStart = React.useCallback(
      (event: React.DragEvent<any>, index: number) => {
        const parent = findParentByClass(event.target as Element, 'carousel-line') as HTMLDivElement | undefined;
        if (!parent) return;

        const dragImage: Element | undefined = createDragImage(parent);
        draggedItemIndex.current = index;

        if (dragImage) {
          event.dataTransfer.setDragImage(dragImage, 0, 0);

          setTimeout(() => removeDragImage());
        }
      },
      [createDragImage, removeDragImage],
    );

    const handleRemove = React.useCallback(
      (id: string) => {
        onApplyOrder(carouselTilesList?.filter(carousel => carousel.id !== id) as IHubCarousel[]);
      },
      [carouselTilesList, onApplyOrder],
    );

    const throttledDragOverFn = throttle((event: React.DragEvent<any>, index: number) => {
      event.preventDefault();

      const parent = findParentByClass(event.target as Element, 'carousel-line') as HTMLDivElement | undefined;

      if (!parent) return;

      // Get the bounding rect of the element for more precise calculations
      const {top, height} = parent.getBoundingClientRect();

      // Check if the drag is in the top or bottom half of the element
      const isTopHalf = event.clientY < top + height / 2;

      // Set hovered index
      setActiveDividerIndex(isTopHalf ? index : index + 1);
    }, 100);

    const handleDragOver = React.useCallback(throttledDragOverFn, [throttledDragOverFn]);

    const handleMoveUp = React.useCallback(
      (index: number) => {
        onApplyOrder(reorderList([index], index - 1, carouselTilesList as IHubCarousel[]));
      },
      [carouselTilesList, onApplyOrder],
    );

    const handleMoveDown = React.useCallback(
      (index: number) => {
        onApplyOrder(reorderList([index], index + 2, carouselTilesList as IHubCarousel[]));
      },
      [carouselTilesList, onApplyOrder],
    );

    if (isLoading) {
      return (
        <Cover center>
          <Template label='cover'>
            <Center textCenter={true} intrinsic={true}>
              <Cluster space='small'>
                <Spinner />
                <Paragraph>Loading Carousels</Paragraph>
              </Cluster>
            </Center>
          </Template>
        </Cover>
      );
    }

    if (!isLoading && carouselTilesList && carouselTilesList?.length === 0) {
      return (
        <Cover center={true}>
          <Template label='cover'>
            <Center textCenter={true} intrinsic={true}>
              <Stack space='large'>
                <Heading level='h3'>No carousels currently assigned.</Heading>
                <Button onClick={() => onCreate()} type='primary' permission={canEdit ? '' : 'hidden'}>
                  + Add Carousel
                </Button>
              </Stack>
            </Center>
          </Template>
        </Cover>
      );
    }

    return (
      <Cover scrolling={true}>
        <Template label='cover'>
          <div ref={wrapperRef} onDragOver={event => handleAutoscroll(event)}>
            <Box
              // eslint-disable-next-line no-console
              onDrop={() => handleDrop()}
              onDragLeave={() => {
                // TODO: Check to see if element leaving is actually outside this Box so the Drag Leave is accurate. See Assmeble Table code.
                // eslint-disable-next-line no-console
                // console.log('Drag Leave');
              }}
              paddingTop='xxxxxxsmall'
              borderColor='success'
              borderStyle='solid'
              borderSize='0.0625rem'
              borderTop={draggedItemIndex.current !== null && activeDividerIndex === 0}
            >
              <Stack space='xlarge'>
                {carouselTilesList?.map((carousel, index) => (
                  <div key={carousel.id} className='carousel-line'>
                    <CarouselBlock
                      index={index + 1}
                      carousel={carousel}
                      titleUrl={programmingRoutes.paths.carouselEditDetailsPage.replace(':id', carousel.id)}
                      onArrowUpClick={() => handleMoveUp(index)}
                      onArrowDownClick={() => handleMoveDown(index)}
                      onDragStart={event => handleDragStart(event, index)}
                      onDragOver={event => handleDragOver(event, index)}
                      onRemove={handleRemove}
                      items={carousel.tiles as ICarouselBlockItem[]}
                      canEdit={canEdit}
                      canDelete={canDelete}
                    />
                    <Divider
                      marginBottom='xxsmall'
                      color={
                        draggedItemIndex.current !== null && activeDividerIndex === index + 1 ? 'success' : 'graphite'
                      }
                    />
                  </div>
                ))}
              </Stack>
            </Box>
          </div>
        </Template>
      </Cover>
    );
  },
);

export default CarouselList;
