import * as React from 'react';
import {useFindQueueQuery} from 'features/channelQueue/channelQueueApi';
import {IChannelQueueParams} from 'models/channelQueue';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {SerializedError} from '@reduxjs/toolkit';
import {IListPayload} from 'models/generic';
import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';
import {ITimeframe, createTimelinesWorker, getTimelineState} from 'views/programming/channel/edit/program/utilities';
import {IStateMsgList} from 'views/programming/channel/utils';

export const defaultParams: IChannelQueueParams = {
  sort: ['order:asc'],
  offset: 0,
  limit: 50000,
};

interface IMemoryQueueApiContext {
  data: IListPayload<IChannelCatalogItem> | undefined;
  isError: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  refetch: () => void;
  queueDataWithState: IChannelCatalogItemWithState[];
  createNewTimelinesAutoFill: (
    timeframes: ITimeframe[],
    lastDraftTimeline: ITimeline | undefined,
  ) => Promise<ITimeline[]>;
  findEpisodeInQueue: (item: any) => IChannelCatalogItemWithState | undefined;
}

interface IMemoryQueueApiProviderProps {
  channelId?: string;
  children: React.ReactNode;
}

export interface IChannelCatalogItemWithState extends IChannelCatalogItem {
  state: string;
  stateMsgList: IStateMsgList[];
}

export const MemoryQueueApiContext = React.createContext<IMemoryQueueApiContext | null>(null);

export const MemoryQueueApiProvider = ({channelId = '', children}: IMemoryQueueApiProviderProps): JSX.Element => {
  const {data, isError, isFetching, error, refetch} = useFindQueueQuery(
    {channelId, ...defaultParams},
    {skip: !channelId, refetchOnMountOrArgChange: true},
  );

  const queueDataWithState: IChannelCatalogItemWithState[] = React.useMemo(
    () => data?.data?.map(data => ({...data, ...getTimelineState(data)})) || [],
    [data?.data],
  );

  const findEpisodeInQueue = React.useCallback(
    (item: any) => {
      const episode = queueDataWithState.find(ep => ep.id === item.episode);
      return episode;
    },
    [queueDataWithState],
  );

  const createNewTimelinesAutoFill = React.useCallback(
    (timeframes: ITimeframe[], lastDraftTimeline: ITimeline | undefined) =>
      createTimelinesWorker(timeframes, lastDraftTimeline, queueDataWithState || []),
    [queueDataWithState],
  );

  const value = React.useMemo(
    () => ({
      data,
      isError,
      isFetching,
      error,
      queueDataWithState,
      refetch,
      createNewTimelinesAutoFill,
      findEpisodeInQueue,
    }),
    [createNewTimelinesAutoFill, data, error, isError, isFetching, refetch, queueDataWithState, findEpisodeInQueue],
  );

  return <MemoryQueueApiContext.Provider value={value}>{children}</MemoryQueueApiContext.Provider>;
};

export const useMemoryQueueApiProvider = (): IMemoryQueueApiContext => {
  const context = React.useContext(MemoryQueueApiContext);
  if (!context) {
    throw new Error('useMemoryQueueApiProvider must be used within a MemoryQueueApiProvider');
  }
  return context;
};
