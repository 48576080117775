import * as React from 'react';
// import {useHistory} from 'react-router-dom';
import {ContentBoxes, ContentBox, Stack, TFormFields, TDirtyFields} from '@pluto-tv/assemble';

// import {useAppPermissions} from 'app/permissions';
import {INestedCarouselConfigProps} from '../nestedPropsInterface';
// import {useUserRegions} from 'helpers/useUserRegions';

import {IHubConfig} from 'models/hubConfigs';
import HubConfigurationFormSection from 'components/hubList/components/HubConfigurationFormSection';
import DistributionDetailsFormSection from 'components/hubList/components/DistributionDetailsFormSection';
import {useAppPermissions} from 'app/permissions';

interface IHubConfigProps extends INestedCarouselConfigProps {
  dirtyFields: TDirtyFields<IHubConfig>;
  form: TFormFields<IHubConfig>;
  model: Partial<IHubConfig>;
  onBlur: (fieldName: keyof IHubConfig, setVal?: boolean) => void;
  onChange: <K extends keyof IHubConfig>(fieldName: K, value: IHubConfig[K]) => void;
  pristineModel: Partial<IHubConfig>;
  setFields: (partialModel: Partial<IHubConfig>) => void;
}

const HubSettings = React.memo(({form, model, onBlur, onChange, setFields}: IHubConfigProps): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const canEdit = ableTo('HUB_EDIT');
  // const {activeRegions, isError: isErrorRegions} = useUserRegions();

  return (
    <ContentBoxes layout='grid' gridMaxCols={2}>
      <ContentBox title='Hub Configurations'>
        <Stack space='small'>
          <HubConfigurationFormSection
            form={form}
            model={model}
            onBlur={onBlur}
            onChange={onChange}
            setFields={setFields}
            canEdit={canEdit}
          />
        </Stack>
      </ContentBox>
      <ContentBox title='Distribution Details'>
        <Stack space='small'>
          <DistributionDetailsFormSection
            form={form}
            model={model}
            setFields={setFields}
            onBlur={onBlur}
            canEdit={canEdit}
          />
        </Stack>
      </ContentBox>
    </ContentBoxes>
  );
});

export default HubSettings;
