import * as React from 'react';
// import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  Heading,
  Paragraph,
  Template,
  TDirtyFields,
  Spinner,
  Toast,
} from '@pluto-tv/assemble';
import {uniq} from 'lodash-es';

import {useAppPermissions} from 'app/permissions';
import {INestedCarouselConfigProps} from '../nestedPropsInterface';
// import {useUserRegions} from 'helpers/useUserRegions';

import {ICarouselBlockItem, IHubCarousel, IHubConfig} from 'models/hubConfigs';
import ReorderPanel from 'views/programming/hubManager/hubs/edit/preview/components/ReorderPanel';
import CrudError from 'components/crudError';
import CarouselList from './components/CarouselList';
import {useServiceCarouselBuilder} from 'views/programming/hubManager/hooks/useServiceCarouselBuilderHook';

const CreateCarouselForm = React.lazy(() => import('components/carouselList/components/CreateCarouselForm'));

interface IHubConfigProps extends INestedCarouselConfigProps {
  dirtyFields: TDirtyFields<IHubConfig>;
  setFields: (partialModel: Partial<IHubConfig>) => void;
  model: Partial<IHubConfig>;
  setUndo: (newPresent: IHubCarousel[], options?: any) => void;
  isHubConfigFetching: boolean;
  isHubConfigError: boolean;
  hubConfigError: any;
}

export default ({
  setFields: setHubFields,
  model: hubModel,
  isHubConfigFetching,
  isHubConfigError,
  hubConfigError,
  setUndo,
}: IHubConfigProps): JSX.Element => {
  const [reorderOpen, setReorderOpen] = React.useState<boolean>(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [areTilesLoading, setAreTilesLoading] = React.useState(true);
  const [carouselTilesList, setCarouselTilesList] =
    React.useState<{tiles: ICarouselBlockItem[]; id: string; displayName: string; tileSize: 'normal' | 'large'}[]>();

  const {ableTo} = useAppPermissions();
  const {executeQuery} = useServiceCarouselBuilder();

  const canEdit = ableTo('CAROUSEL_EDIT');
  const canDelete = ableTo('CAROUSEL_EDIT');
  const getContentTiles = React.useCallback(async () => {
    if (hubModel.region && hubModel.carousels?.length) {
      const carouselIds = uniq(hubModel.carousels.map(ca => ca.id));

      try {
        const response = await executeQuery(hubModel.region, hubModel.kidsOnly || false, carouselIds);

        if (response?.length) {
          const carouselTiles = (hubModel.carousels || []).map(carousel => {
            const tilesMapped: ICarouselBlockItem[] = (response.find(item => item.id === carousel.id)?.tiles || []).map(
              (resp: any) => ({
                // ...resp,
                text: resp.title,
                imageSrc: resp.tileImage,
              }),
            );

            return {
              ...carousel,
              tiles: tilesMapped,
            };
          });
          setCarouselTilesList(carouselTiles);
        }
      } catch (error) {
        Toast.error('Error', 'There was an error retrieving carousel list. Please try again.');
      } finally {
        setAreTilesLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubModel.carousels, hubModel.kidsOnly, hubModel.region]);

  React.useEffect(() => {
    if (hubModel.region) {
      if (hubModel.carousels && hubModel.carousels.length > 0) {
        getContentTiles();
      } else {
        setAreTilesLoading(false);
        setCarouselTilesList([]);
      }
    }
  }, [getContentTiles, hubModel.carousels, hubModel.kidsOnly, hubModel.region]);

  const handleApplyOrder = (hubCarousels: IHubCarousel[]) => {
    setHubFields({
      carousels: hubCarousels,
    });
    setUndo(hubCarousels);
  };

  const openCreate = () => setCreateOpen(true);

  const handleAddCarousel = (carouselToAdd: Partial<IHubCarousel>) => {
    const newCarouselsState = [...(hubModel.carousels || []), carouselToAdd] as IHubCarousel[];

    setHubFields({carousels: newCarouselsState});
    setUndo(newCarouselsState);
  };

  if (isHubConfigFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (isHubConfigError) {
    return <CrudError error={hubConfigError} />;
  }

  return (
    <ContentBoxes layout='cover' coverScrolling={true}>
      <Template label='contentBoxesCover'>
        <ContentBox title='Carousel List'>
          <Template label='header'>
            <Cluster align='center' justify='space-between' space='medium'>
              <Cluster space='medium' align='end'>
                <Heading level='h3' color='secondary'>
                  Carousel List
                </Heading>
                <Paragraph color='secondary'>{hubModel?.carousels?.length ?? 0} Items</Paragraph>
              </Cluster>
              <Cluster space='small'>
                <Button
                  state={(hubModel.carousels || []).length < 2 ? 'disabled' : ''}
                  onClick={() => setReorderOpen(true)}
                  permission={canEdit ? '' : 'hidden'}
                >
                  List View
                </Button>
                {reorderOpen && (
                  <ReorderPanel
                    hubCarousels={hubModel.carousels as IHubCarousel[]}
                    onClose={() => setReorderOpen(false)}
                    onApplyOrder={handleApplyOrder}
                  />
                )}
                <Button onClick={() => openCreate()} type='primary' permission={canEdit ? '' : 'hidden'}>
                  + Add Carousel
                </Button>
                {createOpen && (
                  <React.Suspense fallback={<React.Fragment />}>
                    <CreateCarouselForm
                      addCarouselToHub={true}
                      setCreateOpen={setCreateOpen}
                      primaryBtnText='+ Add'
                      secondPrimaryBtn={false}
                      addCarousel={handleAddCarousel}
                      hubCarouselIds={hubModel.carousels?.map(ca => ca.id)}
                    />
                  </React.Suspense>
                )}
              </Cluster>
            </Cluster>
          </Template>
          <Template label='content'>
            <CarouselList
              carouselTilesList={carouselTilesList}
              isLoading={areTilesLoading}
              onCreate={() => openCreate()}
              onApplyOrder={handleApplyOrder}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};
