import {AllStates, ISelectOption, Select} from '@pluto-tv/assemble';
import {IChannelQuery, useLazyFindLiteQuery} from 'features/channels/channelsApi';

import * as React from 'react';

interface IAutocompleteProps {
  value?: ISelectOption;
  onChange: (value?: ISelectOption) => void;
  activeRegions: string[];
  state?: AllStates;
  id?: string;
}

const Autocomplete = ({
  value,
  onChange,
  activeRegions,
  state,
  id = 'includesChannel',
}: IAutocompleteProps): React.ReactElement => {
  const [searchChannels] = useLazyFindLiteQuery();
  const [channelValue, setChannelValue] = React.useState<ISelectOption | null>(null);

  const handleChange = (value?: ISelectOption) => {
    setChannelValue(value || null);
    onChange(value);
  };

  React.useEffect(() => {
    setChannelValue(value === undefined ? null : value);
  }, [value]);

  const handleChannelsSearch = async (search: string): Promise<ISelectOption[]> => {
    if (!activeRegions.length || !search) {
      return [];
    }

    const params: IChannelQuery = {
      name: search,
      activeRegions,
      archived: false,
    };
    try {
      const response = await searchChannels(params).unwrap();
      if (!response.data || !response.data?.length) {
        return [
          {
            label: 'No Results Found',
            value: '',
            disabled: true,
          },
        ];
      }
      return (
        response.data.map(channel => ({
          label: `${channel.name}${channel.activeRegion ? ' (' + channel.activeRegion.toUpperCase() + ')' : ''}`,
          value: channel.id,
        })) || []
      );
    } catch (error) {
      return [
        {
          label: 'No Results Found',
          value: '',
          disabled: true,
        },
      ];
    }
  };

  return (
    <Select
      id={id}
      state={state}
      clearable={true}
      predicate='value'
      placeholder='Search for channel'
      searchPlaceholder='Search for channel'
      searchable={true}
      sortField='label'
      value={{label: channelValue?.label || '', value: channelValue?.label || ''}}
      onSearch={handleChannelsSearch}
      options={[]}
      autoComplete={true}
      onChange={handleChange}
    />
  );
};

export default Autocomplete;
