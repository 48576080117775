import * as React from 'react';
import {ITableCol, Status, Table, TdLink, TSize} from '@pluto-tv/assemble';
import {TableActions} from 'components/tableActions';
import {IHubConfig, IHubConfigSearch} from 'models/hubConfigs';
import hubRoutes from 'routes/programming.routes';
import {useAppPermissions} from 'app/permissions';

interface IHubListTableProps {
  actionsCol?: boolean;
  onEdit: (id: string) => void;

  hubConfigItems: IHubConfig[] | undefined;
  lazyLoad: () => void;
  isLazyLoading: boolean;
  isError: boolean;
  isLoading: boolean;
  searchParams: IHubConfigSearch | undefined;
  searchHubs: (params: IHubConfigSearch) => void;
}

const TABLE_COLUMN_NAME = {
  'Hub Configuration': 'name',
  'Active Region': 'region',
  Published: 'published',
  createdAt: 'createdAt',
} as const;

const HubConfigListTable = ({
  onEdit,
  actionsCol = true,
  hubConfigItems,
  lazyLoad,
  isLazyLoading,
  isError,
  isLoading,
  searchParams,
  searchHubs,
}: IHubListTableProps): React.ReactElement => {
  const {ableTo} = useAppPermissions();

  const [sortDir, setSortDir]: any = React.useState('asc');
  const [sortCol, setSortCol]: any = React.useState('name');

  const columns = React.useMemo(
    () => [
      {
        label: 'Hub Configuration',
        colWidth: '30rem' as TSize,
        sortable: true,
        transform: row => (
          <TdLink row={row} title={row.name} url={hubRoutes.paths.hubEditPreviewPage.replace(':id', row?.id)} />
        ),
      },
      {
        label: 'Active Region',
        sortable: true,
        transform: row => row.region?.toUpperCase() || 'N/A',
        colMinWidth: '9.6875rem' as TSize,
      },
      {
        label: 'Kids Only',
        transform: (row: IHubConfig) => (
          <Status label={row.kidsOnly ? 'Yes' : 'No'} state={row.kidsOnly ? 'success' : 'neutral'} />
        ),
        colMinWidth: '9.5rem' as TSize,
      },
      {
        label: 'Published',
        sortable: true,
        colMinWidth: '9.5rem' as TSize,
        transform: (row: IHubConfig) => (
          <Status label={row.published ? 'Published' : 'Unpublished'} state={row.published ? 'success' : 'neutral'} />
        ),
      },
      ...(ableTo('HUB_EDIT') && actionsCol
        ? [
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <TableActions
                  row={row}
                  icons={ableTo('HUB_EDIT') ? ['edit'] : []}
                  altTitle={row.name}
                  onClick={(row, icon) => {
                    switch (icon) {
                      case 'edit':
                        onEdit(row.id);
                        break;
                      default:
                    }
                  }}
                />
              ),
            } as ITableCol<IHubConfig>,
          ]
        : []),
    ],
    [ableTo, actionsCol, onEdit],
  );

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    let newSort: string;
    const selectedCol: string = TABLE_COLUMN_NAME[columnName];

    if (columnName === sortCol) {
      if (sortDir === 'asc') {
        newSort = 'desc';
      } else {
        newSort = 'asc';
      }
    } else {
      newSort = 'desc';
    }

    setSortDir(newSort);
    setSortCol(columnName);
    searchHubs({
      ...(searchParams || {}),
      sortBy: selectedCol,
      sortDirection: newSort,
    });
  };

  return (
    <Table
      id='hubConfigurationsList'
      virtual
      fixedHeader={true}
      loading={isLoading}
      wrapContent={true}
      onLazyLoad={lazyLoad}
      lazyLoading={isLazyLoading}
      lazyLoadingMsg='Loading more items...'
      lazyLoadScrollOffset={5}
      sortDir={sortDir ? (sortDir === 'desc' ? 'dsc' : 'asc') : undefined}
      sortCol={sortCol}
      onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
      emptyMsg={
        isError
          ? 'There was an error retrieving hub configurations. Please try again later.'
          : !hubConfigItems?.length && !isLoading
          ? 'No hub configurations found.'
          : undefined
      }
      cols={columns}
      rows={hubConfigItems}
    />
  );
};

export default HubConfigListTable;
