import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {ITerritory} from 'models/territory';

export const territoriesApi = createApi({
  reducerPath: 'territoriesApi',
  tagTypes: ['Territories'],
  baseQuery: baseQueryWithAuth,
  endpoints(builder) {
    return {
      find: builder.query<ITerritory[], void>({
        query: () => ({
          url: `territories`,
          method: 'GET',
        }),
        providesTags: ['Territories'],
      }),
    };
  },
});

export const {useFindQuery} = territoriesApi;
