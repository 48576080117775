export const reorderList = <T>(fromIndices: number[], toIndex: number, items: T[]): T[] => {
  if (fromIndices.length === items.length) return items;
  // find duplicated items and mark them as null so that they can be removed later
  const updatedItems = items.map((item, index) => {
    if (fromIndices.includes(index)) {
      return null;
    }
    return item;
  });

  const itemsToAdd = fromIndices.map(index => items[index]);
  updatedItems.splice(Math.max(0, toIndex), 0, ...itemsToAdd);
  return updatedItems.filter(item => item !== null) as T[];
};
