import * as React from 'react';
import {ICarouselConfigSourceService, ISource} from 'models/carouselConfigs';
import {Popover, Template, Icon, Box, Stack, TIcons} from '@pluto-tv/assemble';

interface ISourceServiceRowStateProps {
  row: ICarouselConfigSourceService;
  allowedSources: ISource[];
  allowedServices: string[];
}

const SourceServiceRowState = ({
  row,
  allowedSources,
  allowedServices,
}: ISourceServiceRowStateProps): React.ReactElement | null => {
  return !allowedSources.includes(row.source) || !allowedServices.includes(row.service) || !row.service ? (
    <Popover trigger='mouseenter'>
      <Template label='trigger'>
        <Icon icon={'warning' as TIcons} verticalAlign='text-bottom' color={'warning' as any} />
      </Template>
      <Template label='popover'>
        <Box paddingX='small' paddingY='xxsmall' background='charcoal'>
          <Stack space='xxxsmall'>
            <Icon icon={'warning' as TIcons} iconAlign='baseline' space='small' color={'warning' as any}>
              <div>Unsupported or missing values.</div>
              <div>Please update.</div>
            </Icon>
          </Stack>
        </Box>
      </Template>
    </Popover>
  ) : null;
};

export default SourceServiceRowState;
