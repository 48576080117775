import * as React from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  Spinner,
  Stack,
  Template,
  Toast,
  Divider,
  Popover,
  Status,
  useValidateForm,
  Dialog,
  Icon,
  Paragraph,
  useCancellableSummon,
} from '@pluto-tv/assemble';
import {cloneDeep, orderBy, some, uniq} from 'lodash-es';
import {useFindByIdQuery, useUpdateMutation, util} from 'features/clips/clipsApi';
import useClipDelete, {ClipNotFoundError, ClipReferencesError} from 'views/content/clip/hooks/useClipDelete';
import {clipDetailsValidator, clipSettingsValidator, clipMediaValidator, clipHeaderValidator} from '../validators';
import {useAppPermissions} from 'app/permissions';
import DeleteConfirmation from 'components/deleteConfirmation';
import ConfirmRouteChange from 'components/confirmRouteChange';
import NotAuthorized from 'components/notAuthorized';
import CrudError from 'components/crudError';
import contentRoutes from 'routes/content.routes';
import {IClip} from 'models/clips';
import {useUserRegionModel} from 'helpers/useUserRegions';
import {useAppDispatch} from 'app/store/hooks';

import ClipDetails from './details';
import ClipSettings from './settings';
import ClipMedia from './media';
import ClipAudio from './captions-audio';
import ClipEpisodes from './episodes';

const clipTabs = ['details', 'settings', 'media', 'captions-audio', 'episodes'] as const;

export type TClipTabs = typeof clipTabs[number];

const getActiveTab = (pathname: string): TClipTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as TClipTabs;

  if (clipTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'details';
};

// Fields we need to have special messaging for
const checkedFields = ['duration', 'breakpoints', 'origin'];

export default (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const {ableTo, permissions} = useAppPermissions();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [summon] = useCancellableSummon();

  const activeTab: TClipTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaveOpen, setIsSaveOpen] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [isTranscodeLoading, setIsTranscodeLoading] = React.useState(false);

  const [activeClip, setActiveClip] = React.useState<IClip>();

  const canDelete = ableTo('CLIP_DELETE');

  const {
    data: clip,
    isError: isClipError,
    error: clipError,
    isLoading: isClipFetching,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [updateClip] = useUpdateMutation();
  const {handleDelete} = useClipDelete();

  const canViewRegion = useUserRegionModel(clip);

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IClip>([
    ...clipHeaderValidator,
    ...clipDetailsValidator,
    ...clipSettingsValidator,
    ...clipMediaValidator,
  ]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(contentRoutes.paths.clipListPage);
    }
  }, [isLeaving, history]);

  React.useEffect(() => {
    if (clip) {
      setActiveClip(clip);
    }
  }, [clip]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const transcodeClip = async (updateModel = false) => {
    if (isTranscodeLoading || model.liveBroadcast) {
      return;
    }

    setIsTranscodeLoading(true);

    try {
      if (updateModel) {
        const workingClip = cloneDeep(model);
        workingClip.contentStatus = 'uploaded';

        await updateClip({id, clip: workingClip, fields: ['contentStatus']}).unwrap();
      }

      await summon.post(`/clips/${id}/process`);

      dispatch(util.invalidateTags(['Clips']));

      updateModel && Toast.success('A Clip transcoding job has been requested.');
    } catch (e) {
      Toast.error('Could not start transcode job. Please try again');
    }

    setIsTranscodeLoading(false);
  };

  const saveData = async () => {
    setIsSaving(true);

    const workingClip = cloneDeep(model);
    const fields = Object.keys(dirtyFields);

    if ((dirtyFields.breakpoints || dirtyFields.origin) && !model.liveBroadcast) {
      workingClip.contentStatus = 'uploaded';
      fields.push('contentStatus');
    }

    // Save data to API here
    try {
      const result = await updateClip({id, clip: workingClip, fields: uniq(fields)}).unwrap();

      if (dirtyFields.breakpoints || dirtyFields.origin) {
        await transcodeClip();
        Toast.success('Success', 'Clip updated. A Clip transcoding job has been requested.');
      } else {
        Toast.success('Success', 'Clip Updated');
      }

      setActiveClip(result);
      setIsSaveOpen(false);
    } catch (e: any) {
      Toast.error(
        'Error',
        e?.data?.message.length > 0
          ? `Failed to save clip. Please try again. ${e?.data?.message}`
          : 'Failed to save clip. Please try again',
      );
    } finally {
      setIsSaving(false);
    }
  };

  const hasFlaggedFields = React.useMemo(
    () => some(checkedFields, field => dirtyFields[field]) && !model.liveBroadcast,
    [dirtyFields, model],
  );

  const saveHandler = () => {
    if (!hasFlaggedFields) {
      saveData();
    } else {
      setIsSaveOpen(true);
    }
  };

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleDeleteOpen = React.useCallback(() => {
    setDeleteOpen(prev => !prev);
  }, []);

  const deleteHandler = React.useCallback(async () => {
    if (!canDelete) {
      toggleDeleteOpen();
      return;
    }

    try {
      await handleDelete(id);
      toggleDeleteOpen();
      Toast.success('Success', 'Clip Removed');
      cancelHandler();
    } catch (error: any) {
      let message = '';
      if (error instanceof ClipReferencesError) {
        message = 'This clip is referenced by one or more episodes. Please remove all episode references to delete.';
      } else if (error instanceof ClipNotFoundError) {
        message = 'Failed to remove clip. Clip not found.';
      }
      Toast.error('Error', message || `Failed to remove clip. Please try again.`);
    }
  }, [canDelete, handleDelete, id, toggleDeleteOpen]);

  // Handler for clip initial load or after save
  React.useEffect(() => {
    if (activeClip) {
      const clonedClip = cloneDeep(activeClip);

      // Sort and de-dupe certain fields on load
      clonedClip.actors = orderBy([...new Set(clonedClip.actors || [])], actor => actor.toLowerCase());
      clonedClip.directors = orderBy([...new Set(clonedClip.directors || [])], director => director.toLowerCase());
      clonedClip.producers = orderBy([...new Set(clonedClip.producers || [])], producer => producer.toLowerCase());
      clonedClip.tags = orderBy([...new Set(clonedClip.tags || [])], tag => tag.toLowerCase());
      clonedClip.regionFilter = orderBy([...new Set(clonedClip.regionFilter || [])], region => region.toLowerCase());
      clonedClip.writers = orderBy([...new Set(clonedClip.writers || [])], writer => writer.toLowerCase());

      const channelAssociation = clonedClip.tags.find(tag => tag.indexOf('channel_id_') === 0);

      if (channelAssociation) {
        clonedClip.channelAssociation = channelAssociation.replace('channel_id_', '');
      }

      setModel(clonedClip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClip]);

  if (isClipError) {
    return <CrudError error={clipError} />;
  }

  if (isClipFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (!ableTo('CLIP_VIEW') || !canViewRegion) {
    return <NotAuthorized />;
  }

  return (
    <>
      <Dialog isOpen={isSaveOpen} onClose={() => setIsSaveOpen(false)} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>
            <Icon icon='warning' color='warning' space='xxsmall'>
              Confirm Changes
            </Icon>
          </Heading>
        </Template>
        <Template label='body'>
          <Stack space='large'>
            <Paragraph color='secondary'>Some of your changes could have unintended consequences:</Paragraph>
            {dirtyFields.duration && (
              <Paragraph color='secondary'>
                Changing the clip duration could impact the duration of any episode, or channel timeline(s) that this
                clip is used in.
              </Paragraph>
            )}
            {dirtyFields.breakpoints && (
              <Paragraph color='secondary'>
                Changing breakpoints could result in the clip being transcoded again.
              </Paragraph>
            )}
            {dirtyFields.origin && (
              <Paragraph color='secondary'>
                Changing the origin URL could result in the clip being transcoded again.
              </Paragraph>
            )}
            <Paragraph color='secondary'>Are you sure you want to save?</Paragraph>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <Button type='solid' state={isSaving ? 'disabled' : ''} size='small' onClick={cancelHandler}>
              Discard Changes
            </Button>
            <Cluster space='small'>
              <Button ghost={true} state={isSaving ? 'disabled' : ''} size='small' onClick={() => setIsSaveOpen(false)}>
                Review Changes
              </Button>
              <Button size='small' state={isSaving ? 'thinking' : ''} type='primary' onClick={saveData}>
                Save and Proceed
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={saveHandler}
        hasFlaggedFields={hasFlaggedFields}
        isValid={formState.isValid}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel.name}
                </Heading>
                <Cluster align='flex-start' space='medium' wrap={false}>
                  <Cluster space='xxsmall' wrap={false}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Active Region:
                    </Heading>
                    <Heading level='h5' color='primary'>
                      {pristineModel.activeRegion?.toUpperCase()}
                    </Heading>
                  </Cluster>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                {!pristineModel.liveBroadcast && (
                  <Button
                    permission={permissions.CLIP_EDIT}
                    state={formState.isDirty ? 'disabled' : isTranscodeLoading ? 'thinking' : ''}
                    onClick={() => transcodeClip(true)}
                  >
                    Transcode
                  </Button>
                )}
                <Popover manualTrigger={true} visible={publishOpen} onClickOutside={() => setPublishOpen(false)}>
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={permissions.CLIP_EDIT}
                    >
                      {model.published ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Status
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({published: false});
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({published: true});
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
                {canDelete && (
                  <Popover manualTrigger={true} visible={deleteOpen} onClickOutside={() => setDeleteOpen(false)}>
                    <Template label='trigger'>
                      <Button id='delete' type='delete' onClick={() => toggleDeleteOpen()}>
                        Delete
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <DeleteConfirmation
                        message={'Are you sure you want to delete ' + pristineModel.name + '?'}
                        cancelButtonFunction={() => toggleDeleteOpen()}
                        proceedButtonFunction={() => deleteHandler()}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Cluster>
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              <Button
                active={activeTab === 'details'}
                onClick={() => history.push(contentRoutes.paths.clipEditDetailsPage.replace(':id', id))}
              >
                Details
              </Button>
              <Button
                active={activeTab === 'settings'}
                onClick={() => history.push(contentRoutes.paths.clipEditSettingsPage.replace(':id', id))}
              >
                Settings
              </Button>
              <Button
                active={activeTab === 'media'}
                onClick={() => history.push(contentRoutes.paths.clipEditMediaPage.replace(':id', id))}
              >
                Media
              </Button>
              <Button
                active={activeTab === 'captions-audio'}
                onClick={() => history.push(contentRoutes.paths.clipEditCaptionsAudioPage.replace(':id', id))}
              >
                Captions & Audio
              </Button>
              <Button
                active={activeTab === 'episodes'}
                onClick={() => history.push(contentRoutes.paths.clipEditEpisodesPage.replace(':id', id))}
              >
                Episodes
              </Button>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'details' && (
            <ClipDetails
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'settings' && (
            <ClipSettings
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'media' && (
            <ClipMedia
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              clipId={id}
            />
          )}
          {activeTab === 'captions-audio' && (
            <ClipAudio
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'episodes' && (
            <ClipEpisodes
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              clipId={id}
            />
          )}
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={cancelHandler} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={saveHandler}
                  id='save'
                  permission={permissions.CLIP_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
