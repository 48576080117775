import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IAgeRange} from 'models/ageRanges';

export const ageRangesApi = createApi({
  reducerPath: 'ageRangesApi',
  tagTypes: ['AgeRanges'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IAgeRange[], void>({
      query: () => ({
        url: `age-ranges`,
        method: 'GET',
      }),
      providesTags: ['AgeRanges'],
    }),
  }),
});

export const {useFindQuery} = ageRangesApi;
