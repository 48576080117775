/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {RootState} from 'app/store';
import {BaseQueryApi, FetchArgs, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react';
import {logout} from 'features/user/userSlice';
import {RetryOptions} from '@reduxjs/toolkit/dist/query/retry';

const baseQuery = fetchBaseQuery({
  baseUrl: '/v1/',
  prepareHeaders(headers, {getState}) {
    const token = (getState() as RootState).user.jwt;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const getStaggeredBaseQuery = (maxRetries = 2) => {
  return retry(baseQuery, {
    maxRetries,
  });
};

const isInvalidOrExpiredTokenError = (errorData: any): boolean =>
  errorData?.statusCode === 401 && errorData?.message?.toLowerCase() === 'invalid or expired jwt';

export const baseQueryWithAuth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const result = await baseQuery(args, api, extraOptions);
  if (isInvalidOrExpiredTokenError(result?.error?.data)) {
    api.dispatch(logout());
  }
  return result;
};

export const getStaggeredQueryWithAuth = (maxRetries = 2): any => {
  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {} & RetryOptions) => {
    const result = await getStaggeredBaseQuery(maxRetries)(args, api, extraOptions);
    if (isInvalidOrExpiredTokenError(result?.error?.data)) {
      api.dispatch(logout());
    }
    return result;
  };
};
