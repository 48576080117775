import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IChannelRepeatScheduleQuery} from 'models/channelProgram';

const getParamsFromModel = (
  searchModel: Partial<Omit<IChannelRepeatScheduleQuery, 'updatedAt'> & {channelId: string}> = {},
) => {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;

    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }
    return [...acc, {[key]: encodeURIComponent(value)}];
  }, [] as {[key in keyof Partial<IChannelRepeatScheduleQuery>]: string | number}[]);
};

export const channelProgramApi = createApi({
  reducerPath: 'channelProgramApi',
  tagTypes: ['channelProgram', 'channelProgramLibrary'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    repeatSchedule: builder.query<any[], Partial<IChannelRepeatScheduleQuery> & {channelId: string}>({
      query: ({channelId, ...restParams}) => {
        const paramsString = getParamsFromModel(restParams)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(`${paramsString}`);
        return {
          url: `channels/${channelId}/repeat-schedule?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      providesTags: ['channelProgram', 'channelProgramLibrary'],
    }),
  }),
});

export const {useRepeatScheduleQuery, useLazyRepeatScheduleQuery} = channelProgramApi;
