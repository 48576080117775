import React from 'react';
import {FormItem, Grid, ISelectOption, Select, Stack, TextInput, TFormFields, Toggle} from '@pluto-tv/assemble';
import {IHubConfig} from 'models/hubConfigs';
import {IActiveRegion} from 'models/activeRegions';

interface IHubConfigurationFormSectionProps {
  isCreateHubForm?: boolean;
  hubNameInputId?: string;
  form: TFormFields<IHubConfig>;
  model: Partial<IHubConfig>;
  activeRegions?: IActiveRegion[];
  onBlur: (fieldName: keyof IHubConfig, setVal?: boolean) => void;
  onChange: <K extends keyof IHubConfig>(fieldName: K, value: IHubConfig[K]) => void;
  setFields: (partialModel: Partial<IHubConfig>) => void;
  canEdit: boolean;
}

const HubConfigurationFormSection = React.memo(
  ({
    isCreateHubForm = false,
    activeRegions = [],
    hubNameInputId = 'hubName',
    form,
    model,
    onBlur,
    onChange,
    setFields,
    canEdit = false,
  }: IHubConfigurationFormSectionProps) => {
    return (
      <Stack space='small'>
        <FormItem {...form.name} onBlur={() => onBlur('name')} permission={canEdit ? '' : 'disabled'}>
          <TextInput
            onChange={value => {
              onChange('name', value);
            }}
            id={hubNameInputId}
            value={model.name}
          />
        </FormItem>
        <FormItem {...form.displayName} onBlur={() => onBlur('displayName')} permission={canEdit ? '' : 'disabled'}>
          <TextInput onChange={value => onChange('displayName', value)} id='hubDisplayName' value={model.displayName} />
        </FormItem>
        {isCreateHubForm && (
          <FormItem {...form?.region} onBlur={() => onBlur('region')} permission={canEdit ? '' : 'disabled'}>
            <Select
              onChange={value => {
                const region = (value as ISelectOption).value;
                setFields({
                  region,
                });
              }}
              value={{label: model.region, value: model.region} as ISelectOption}
              id='activeRegion'
              predicate='value'
              options={activeRegions.map(ar => ({
                label: `${ar.name} (${ar.code})`,
                value: ar.code.toLowerCase(),
              }))}
            />
          </FormItem>
        )}
        <Grid cols={2} gap='xxlarge'>
          <FormItem
            {...form.expiresInMilliseconds}
            onBlur={() => onBlur('expiresInMilliseconds')}
            helpText={
              form.expiresInMilliseconds?.helpText ? form.expiresInMilliseconds.helpText : 'Enter the value in minutes.'
            }
            helpTextColor={form.expiresInMilliseconds?.helpText ? 'error' : 'info'}
            permission={canEdit ? '' : 'disabled'}
          >
            <TextInput
              onChange={value => {
                onChange('expiresInMilliseconds', value);
              }}
              type='number'
              id='refreshRate'
              value={model.expiresInMilliseconds}
            />
          </FormItem>
          <FormItem
            {...form?.kidsOnly}
            helpText='Determines whether the hub is intended for kids.'
            helpTextColor='info'
            child='Toggle'
            onBlur={() => onBlur('kidsOnly')}
            permission={canEdit ? '' : 'disabled'}
          >
            <Toggle label='Yes' value={model.kidsOnly} id='kidsOnly' onChange={value => setFields({kidsOnly: value})} />
          </FormItem>
        </Grid>
      </Stack>
    );
  },
);

export default HubConfigurationFormSection;
