import {IHubConfig} from 'models/hubConfigs';

export const hubSaveParser = (model: Partial<IHubConfig>): Partial<IHubConfig> => {
  const postModel: Partial<IHubConfig> = {
    kidsOnly: false,
    published: false,
    ...model,
    expiresInMilliseconds:
      model.expiresInMilliseconds && Number.isInteger(model.expiresInMilliseconds) && model.expiresInMilliseconds > 0
        ? model.expiresInMilliseconds * 60 * 1000
        : undefined,
    modifier: 'cms_tooling',
  };

  return postModel;
};
