import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {ILanguage} from 'models/languages';

export const languagesApi = createApi({
  reducerPath: 'languagesApi',
  tagTypes: ['Languages'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<ILanguage[], void>({
      query: () => ({
        url: `languages`,
        method: 'GET',
      }),
    }),
  }),
});

export const {useFindQuery} = languagesApi;
