import {getHubSearchLazyLoadProviderContext} from 'components/providers/hub/getHubSearchLazyLoadProvider';
import {IHubSearchData, ISearchData} from 'components/hooks/hub/useSearchHubData';
import {IHubConfig, IHubConfigSearch} from 'models/hubConfigs';

const {
  useHubSearchLazyLoadProvider: useHubSearchlLazyLoadProvider,
  HubSearchLazyLoadProvider: HubSearchLazyLoadProvider,
} = getHubSearchLazyLoadProviderContext<
  IHubConfig,
  IHubConfigSearch,
  IHubSearchData<ISearchData<IHubConfig>, IHubConfigSearch>
>();

export {useHubSearchlLazyLoadProvider, HubSearchLazyLoadProvider};
