import * as React from 'react';

import {
  Box,
  Button,
  Cluster,
  Carousel,
  Click,
  Icon,
  FormItem,
  Heading,
  Select,
  TextInput,
  Paragraph,
  Popover,
  Stack,
  Template,
  TSize,
  useValidateForm,
  ISelectOption,
} from '@pluto-tv/assemble';

// import {ICarouselConfig} from 'models/carouselConfigs';
import DeleteConfirmation from 'components/deleteConfirmation';
import {ICarouselBlockItem, IHubCarousel} from 'models/hubConfigs';
import {hubCarouselConfigsValidator} from 'views/programming/hubManager/hubs/validators';

export interface ICarouselBlockProps {
  index?: number;
  imageBorderRadius?: TSize;
  imageWidth?: TSize;
  imageHeight?: TSize;
  height?: TSize;
  items: ICarouselBlockItem[];
  onArrowUpClick?: () => void;
  onArrowDownClick?: () => void;
  onDragStart?: (event: React.DragEvent<any>) => void;
  onDragOver?: (event: React.DragEvent<any>) => void;
  onItemClick?: (itemIndex: number, item: ICarouselBlockItem) => void;
  onRemove?: (carouselId: string) => void;
  carousel: IHubCarousel;
  titleUrl?: string;
  truncateItemTextOnLine?: number;
  canEdit: boolean;
  canDelete: boolean;
}

const CarouselBlock = React.memo(
  ({
    index,
    imageBorderRadius,
    imageWidth = '12.125rem',
    imageHeight = '6.6875rem',
    height = '9.5rem',
    items,
    onArrowUpClick,
    onArrowDownClick,
    onDragStart,
    onDragOver,
    onItemClick,
    onRemove,
    carousel,
    titleUrl,
    truncateItemTextOnLine = 2,
    canEdit,
    canDelete,
  }: ICarouselBlockProps) => {
    const [editPopoverOpen, setEditPopoverOpen] = React.useState(false);
    const [isRemoveCarouselOpen, setIsRemoveCarouselOpen] = React.useState(false);

    const {
      form,
      model,
      onBlur,
      onChange,
      setModel,
      setFields,
      state: formState,
    } = useValidateForm<IHubCarousel>(hubCarouselConfigsValidator, 'immediate');

    React.useEffect(() => {
      setModel(carousel);
    }, [carousel, setModel]);

    const handleRemove = () => {
      onRemove?.(carousel.id);
    };

    return (
      <Box onDragOver={onDragOver}>
        <Stack space='xsmall'>
          <Cluster justify='space-between' space='small' wrap={false}>
            <Cluster fullWidth={true} space='xxsmall' wrap={false}>
              {canEdit && onDragStart && <Icon icon='drag' draggable={true} onDragStart={onDragStart} />}
              {titleUrl && (
                <Click
                  size='medium'
                  color='primary'
                  hoverColor='primaryLight'
                  href={titleUrl}
                  truncate={true}
                  truncateBackgroundHover='pewter'
                  hrefTarget='_blank'
                >
                  {index}. {carousel.displayName}
                </Click>
              )}
              {!titleUrl && (
                <Heading level='h4' truncate={true} truncateBackgroundHover='pewter'>
                  {index}. {carousel.displayName}
                </Heading>
              )}
            </Cluster>
            <Cluster space='small' wrap={false}>
              {canEdit && onArrowUpClick && <Icon onClick={onArrowUpClick} icon='directionup' />}
              {canEdit && onArrowDownClick && <Icon onClick={onArrowDownClick} icon='directiondown' />}
              <Popover manualTrigger={true} visible={editPopoverOpen} onClickOutside={() => setEditPopoverOpen(false)}>
                <Template label='trigger'>
                  <Icon icon='edit' onClick={() => setEditPopoverOpen(true)} />
                </Template>
                <Template label='popover'>
                  <Box padding='small' background='charcoal' width='16.25rem'>
                    <form id='carouselForm'>
                      <Stack space='small'>
                        <FormItem {...form.name} child='Paragraph' permission={canEdit ? '' : 'hidden'}>
                          <Paragraph>{model.name || 'N/A'}</Paragraph>
                        </FormItem>
                        <FormItem
                          {...form.displayName}
                          onBlur={() => onBlur('displayName')}
                          permission={canEdit ? '' : 'disabled'}
                        >
                          <TextInput
                            id='displayName'
                            value={model.displayName}
                            onChange={val => onChange('displayName', val)}
                          />
                        </FormItem>
                        <FormItem label='Tile Size' permission={canEdit ? '' : 'disabled'}>
                          <Select
                            predicate='value'
                            value={
                              {
                                label: model.tileSize,
                                value: model.tileSize,
                              } as ISelectOption
                            }
                            options={[
                              {label: 'Normal', value: 'normal'},
                              {label: 'Large', value: 'large'},
                            ]}
                            onChange={value => {
                              setFields?.({tileSize: (value as ISelectOption)?.value as 'normal' | 'large'});
                            }}
                          />
                        </FormItem>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='xxsmall'>
                            <Button ghost={true} onClick={() => setEditPopoverOpen(false)}>
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              onClick={() => alert('Update Clicked')}
                              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                              permission={canEdit ? '' : 'disabled'}
                            >
                              Update
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Stack>
                    </form>
                  </Box>
                </Template>
              </Popover>

              {canDelete && (
                <Popover
                  manualTrigger={true}
                  visible={isRemoveCarouselOpen}
                  onClickOutside={() => setIsRemoveCarouselOpen(false)}
                >
                  <Template label='trigger'>
                    <Icon
                      icon='delete'
                      color='delete'
                      hoverColor='deleteLight'
                      onClick={() => setIsRemoveCarouselOpen(isRemoveCarouselOpen => !isRemoveCarouselOpen)}
                    />
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message='Are you sure you want to remove this carousel?'
                      cancelButtonFunction={() => setIsRemoveCarouselOpen(false)}
                      proceedButtonFunction={async () => {
                        setIsRemoveCarouselOpen(false);
                        handleRemove();
                      }}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Cluster>
          <Carousel
            imageBorderRadius={imageBorderRadius}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            height={height}
            onItemClick={onItemClick}
            truncateItemTextOnLine={truncateItemTextOnLine}
            items={
              items.length
                ? items
                : //Carousels that return no tile metadata should display up to 10 tiles with “Pluto TV Automated” image
                  Array.from({length: 10}, () => ({imageSrc: '/pluto-automated.png', text: ''}))
            }
          />
        </Stack>
      </Box>
    );
  },
);

CarouselBlock.displayName = 'CarouselBlock';
export default CarouselBlock;
