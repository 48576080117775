import * as React from 'react';
import {
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  Button,
  useValidateForm,
  FormItem,
  Select,
  TextInput,
  Expand,
  DateTime,
  IDateRange,
} from '@pluto-tv/assemble';
import {IUserCampaignSearch} from 'models/users';

import {useUserRegions} from 'helpers/useUserRegions';
import {TSortDirection} from 'models/generic';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {IListCampaignQuery} from 'models/campaigns';
import {CampaignFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import {useFindQuery as useFindAppnamesQuery} from 'features/appnames/appnamesApi';
import {orderBy} from 'lodash-es';

export interface ICampaignSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isSearchExpanded: boolean;
  showFavoriteSearch: boolean;
  isFetching: boolean;
  search?: IUserCampaignSearch;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserCampaignSearch) => void;
  onClear: () => void;
}

const campaignNameId = 'campaignName';

const CampaignSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isSearchExpanded,
    showFavoriteSearch = false,
    isFetching,
    search,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: ICampaignSearchBarProps) => {
    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: searchSetModel,
      form: searchForm,
      getValidation: searchGetValidation,
      onBlur: searchOnBlur,
    } = useValidateForm<IListCampaignQuery>(
      [
        {name: 'name', label: 'Campaign Name'},
        {name: 'activeRegion'},
        {name: 'published'},
        {name: 'officeOnly'},
        {name: 'distribution'},
        {name: 'start'},
        {name: 'end'},
      ],
      'ask',
    );

    const [searchSelected, setSearchSelected] = React.useState<IUserCampaignSearch | undefined>();

    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const {
      activeRegions,
      //isFetching: isUserRegionsFetching,
      //isError: isUserRegionsError,
    } = useUserRegions();

    const {
      data: appNames,
      //isFetching: isFetchingAppNames,
      //isError: isErrorAppNames,
    } = useFindAppnamesQuery({offset: 0, limit: 1000, sort: 'name:asc'});

    const handleSearch = async () => {
      const validation = await searchGetValidation();

      if (!validation.state.isValid) {
        return;
      }

      onSearch({
        name: '',
        model: validation.model,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
      }
    }, [search, searchSetModel]);

    React.useEffect(() => {
      if (!isFetching && isSearchExpanded) {
        const campaignNameInput = document.getElementById(campaignNameId);
        campaignNameInput?.focus({
          preventScroll: true,
        });
      }
    }, [isSearchExpanded, isFetching]);

    const searchSelectedHandler = (search: IUserCampaignSearch) => {
      setSearchSelected(search);
      searchSetModel(search.model);
      onSearch(search);
    };

    const clearHandler = () => {
      onClear();
      searchSetModel({});
      setSearchSelected(undefined);
    };

    const distributionOptions = React.useMemo(() => {
      return (
        appNames?.data?.map(ar => ({
          label: `${ar.name}`,
          value: ar.name,
        })) || []
      );
    }, [appNames?.data]);

    return (
      <Expand width='21rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box
            background='pewter'
            paddingY={'medium'}
            paddingRight='medium'
            paddingLeft={'medium'}
            fullHeight={true}
            marginRight={'large'}
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
          >
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  {showFavoriteSearch && (
                    <CampaignFavoriteSearch
                      searchModel={{
                        name: '',
                        model: searchModel,
                        sortCol: sortCol,
                        sortDir: sortDir,
                      }}
                      getValidation={searchGetValidation}
                      searchSelected={searchSelected}
                      onSearchSelected={searchSelectedHandler}
                      onClearSelection={clearHandler}
                    />
                  )}
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {/* Need to force form re-render on open searchBar to make autoFocus field work properly */}
                {isSearchExpanded && (
                  <form
                    onSubmit={ev => {
                      ev.preventDefault();
                      setTimeout(() => handleSearch());
                    }}
                  >
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      {/* negative margin here to counter act the odd space from the input on the line above. */}
                      <Box marginTop='xsmallNegative'>
                        <Stack space='small'>
                          <Stack space='xlarge'>
                            <FormItem
                              {...searchForm.name}
                              onBlur={() => {
                                searchOnBlur('name');
                              }}
                            >
                              <TextInput
                                id={campaignNameId}
                                placeholder='Search by Campaign Name'
                                value={searchModel.name}
                                onChange={val => {
                                  searchOnChange('name', val);
                                }}
                              />
                            </FormItem>
                          </Stack>
                          <Stack space='small'>
                            <FormItem label='Active Region'>
                              <Select
                                multiselect={true}
                                placeholder='Select Active Region'
                                clearable={true}
                                id='targetRegion'
                                value={searchModel.activeRegion?.map(v => ({label: v, value: v})) || []}
                                options={activeRegions.map(ar => ({
                                  label: `${ar.name} (${ar.code})`,
                                  value: ar.code,
                                }))}
                                onChange={value => {
                                  if (!value) {
                                    searchSetFields({activeRegion: []});
                                  } else {
                                    searchSetFields({activeRegion: value.map(v => v.value)});
                                  }
                                }}
                                predicate='value'
                              />
                            </FormItem>
                            <FormItem label='Published'>
                              <Select
                                clearable={true}
                                placeholder='Published?'
                                options={[{label: 'Yes'}, {label: 'No'}]}
                                value={
                                  searchModel.published === undefined
                                    ? undefined
                                    : searchModel.published
                                    ? {label: 'Yes'}
                                    : {label: 'No'}
                                }
                                onChange={value => {
                                  if (!value) {
                                    searchSetFields({published: undefined});
                                  } else {
                                    searchSetFields({published: value.label === 'Yes'});
                                  }
                                }}
                              />
                            </FormItem>
                            <FormItem label='Office Only'>
                              <Select
                                clearable={true}
                                placeholder='Office Only?'
                                options={[{label: 'Yes'}, {label: 'No'}]}
                                value={
                                  searchModel.officeOnly === undefined
                                    ? undefined
                                    : searchModel.officeOnly
                                    ? {label: 'Yes'}
                                    : {label: 'No'}
                                }
                                onChange={value => {
                                  if (!value) {
                                    searchSetFields({officeOnly: undefined});
                                  } else {
                                    searchSetFields({officeOnly: value.label === 'Yes'});
                                  }
                                }}
                              />
                            </FormItem>
                            <FormItem label='Distribution'>
                              <Select
                                id='distribution'
                                placeholder='Select Distribution'
                                predicate='value'
                                multiselect
                                searchable
                                clearable
                                value={searchModel.distribution?.map(v => ({label: v, value: v}))}
                                options={distributionOptions}
                                onChange={value => {
                                  searchSetFields({distribution: !value ? [] : value.map(v => v.value)});
                                }}
                                onSearch={term =>
                                  orderBy(
                                    (distributionOptions || [])
                                      .filter(p => p.label.toLowerCase().includes(term.toLowerCase()))
                                      .map(p => ({label: p.label, value: p.value}), 'label'),
                                  ) || []
                                }
                              />
                            </FormItem>
                            <FormItem label='Publication Date Range'>
                              <DateTime
                                id='uploadDate'
                                range={true}
                                placeholder='Search by publication date range'
                                value={
                                  searchModel.start && searchModel.end
                                    ? {start: new Date(searchModel.start), end: new Date(searchModel.end)}
                                    : undefined
                                }
                                clearable={true}
                                appendToBody={true}
                                onChange={value => {
                                  if (value) {
                                    const v = value as IDateRange;
                                    searchSetFields({start: v.start.toISOString(), end: v.end?.toISOString()});
                                  } else {
                                    searchSetFields({start: undefined, end: undefined});
                                  }
                                }}
                              />
                            </FormItem>
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button type='primary' state={isFetching ? 'thinking' : ''} onClick={handleSearch}>
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

CampaignSearchBar.displayName = 'CampaignSearchBar';
export default CampaignSearchBar;
