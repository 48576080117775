import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {ICategory} from 'models/categories';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  tagTypes: ['Categories'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<ICategory[], void>({
      query: () => ({
        url: `categories`,
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
  }),
});

export const {useFindQuery} = categoriesApi;
