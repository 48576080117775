export const staggingRoutesMapping = {
  PUBSTE1: {
    TNG: 'https://service-publishing-client.pubste1.plutostaging.tv',
    Classic: 'https://inf-app-curation-website.pubste1.plutostaging.tv/system-manager',
  },
  PUBSTE2: {
    TNG: 'https://service-publishing-client.pubste2.plutostaging.tv',
    Classic: 'https://inf-app-curation-website.pubste2.plutostaging.tv/system-manager',
  },
  PUBSTE3: {
    TNG: 'https://service-publishing-client.pubste3.plutostaging.tv',
    Classic: 'https://inf-app-curation-website.pubste3.plutostaging.tv/system-manager',
  },
  PUBSTE4: {
    TNG: 'https://service-publishing-client.pubste4.plutostaging.tv',
    Classic: 'https://inf-app-curation-website.pubste4.plutostaging.tv/system-manager',
  },
  PREPROD: {
    TNG: 'https://cms.plutopreprod.tv',
    Classic: 'https://pub.plutopreprod.tv/system-manager',
  },
  PRODUCTION: {
    TNG: 'https://cms.pluto.tv',
    Classic: 'https://pub.pluto.tv/system-manager',
  },
};
