import {ISelectOption} from '@pluto-tv/assemble';
import {serviceMappings} from 'components/carouselList/helpers/SourceServiceHelper';
import {ICarouselConfigSourceService} from 'models/carouselConfigs';

export type ISource = keyof typeof serviceMappings;

export const getServiceOptions = (source: ISource): ISelectOption[] => {
  return Object.entries(serviceMappings[source] || []).map(([value, label]) => ({label, value}));
};

export const sourceOptions: {label: string; value: ISource}[] = [
  {label: 'Campaigns', value: 'campaigns'},
  {label: 'Feature Store', value: 'feature-store'},
  {label: 'Media Catalog', value: 'mediacatalog'},
  {label: 'Recommended', value: 'recommender'},
  {label: 'VOD Collections', value: 'vod'},
  {label: 'Watchlist', value: 'watchlist'},
  {label: 'ML Carousels', value: 'mlcarousels'},
];

// this function is used to get the used source and service options in order to not to repeat the same source/service combination
export const getUsedSourceAndServiceOptions = (
  carouselSourceServiceList: ICarouselConfigSourceService[],
  index: number,
): {[key in ISource]: {value: string; disabled: boolean}[]} => {
  return carouselSourceServiceList.reduce((acc, sourceService, i) => {
    if (i === index) return acc;
    acc[sourceService.source] = [...(acc[sourceService.source] || []), {value: sourceService.service, disabled: false}];
    return acc;
  }, {} as {[key in ISource]: {value: string; disabled: boolean}[]});
};

const sourceValueKeys = sourceOptions.reduce((acc, item) => {
  acc[item.value] = getServiceOptions(item.value).map(s => s.value);
  return acc;
}, {} as {[key in ISource]: {value: string; disabled: boolean}[]});

export const getSourceServiceOptions = (
  usedSourceOptions: {[key in ISource]: {value: string; disabled: boolean}[]} = {} as {
    [key in ISource]: {value: string; disabled: boolean}[];
  },
): {[key in ISource]: {value: string; disabled: boolean}[]} => {
  return Object.entries(sourceValueKeys).reduce((acc, [source]) => {
    acc[source] = (sourceValueKeys[source] || []).map(s => ({
      value: s,
      disabled: !!(usedSourceOptions[source] || []).find(ser => ser.value === s),
    }));
    return acc;
  }, {} as {[key in ISource]: {value: string; disabled: boolean}[]});
};

export const getFilteredSourceOptions = (
  filteredSourceServices: {[key in ISource]: {value: string; disabled: boolean}[]},
): ISelectOption[] => {
  return sourceOptions.filter(({value}) => filteredSourceServices[value].length);
};

export const getFilteredServiceOptions = (
  source: ISource,
  filteredSourceServices: {[key in ISource]: {value: string; disabled: boolean}[]},
): ISelectOption[] => {
  const serviceOptions = getServiceOptions(source);

  return serviceOptions.map(s => ({
    ...s,
    disabled: filteredSourceServices[source].find(val => val.value === s.value)?.disabled || false,
  }));
};

export const isAssociatedCarouselRequired = (source: ISource, service: string): boolean => {
  return (
    ['vod', 'mlcarousels'].includes(source) ||
    (source === 'recommender' && service.includes('similar-')) ||
    (source === 'mediacatalog' && service === 'main-categories')
  );
};
