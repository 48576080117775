import * as React from 'react';
import {useSelector} from 'react-redux';
import {ICarouselBuilderResult} from 'views/programming/hubManager/hooks/interfaces';
import {RootState} from 'app/store';

interface IUseCarouselContentCacheReturn {
  cachedCarouselContents: ICarouselBuilderResult[];
  cachedIds: string[];
}

export const useCarouselContentCache = (): IUseCarouselContentCacheReturn => {
  const carouselContentCache = useSelector((state: RootState) => state.carouselContent.queries);

  const cachedCarouselContents: ICarouselBuilderResult[] = React.useMemo(
    () =>
      Object.values(carouselContentCache as Record<string, {data: {carousels: ICarouselBuilderResult[]}}>).flatMap(
        item => {
          return item.data?.carousels?.map(({id, tiles}) => ({id, tiles}));
        },
      ),
    [carouselContentCache],
  ).filter(Boolean);

  const cachedIds = React.useMemo(() => cachedCarouselContents.map(item => item.id), [cachedCarouselContents]);

  return {
    cachedCarouselContents,
    cachedIds,
  };
};
